import { Trans } from "@lingui/macro";
import { Modal, Title } from "@mantine/core";

import { GetLocationsItem } from "@/shared/api/generated/schemas";

import UpdateLocationForm from "./update-location-form";

export default function UpdateLocationModal({
  location,
  opened,
  close,
}: {
  location: GetLocationsItem;
  opened: boolean;
  close: () => void;
}) {
  return (
    <>
      <Modal
        opened={opened}
        onClose={close}
        size="lg"
        title={
          <Title size="sm">
            <Trans>Endre lokasjon</Trans>
          </Title>
        }
      >
        <UpdateLocationForm location={location} close={close} />
      </Modal>
    </>
  );
}
