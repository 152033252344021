import { t, Trans } from "@lingui/macro";
import { Menu, Text, Title } from "@mantine/core";
import { modals, openConfirmModal } from "@mantine/modals";
import { useQueryClient } from "@tanstack/react-query";

import {
  getGetLocationsQueryKey,
  useDeleteLocation,
} from "@/shared/api/generated/locations";

export default function DeleteLocationMenuItem({
  id,
  name,
}: {
  id: string;
  name: string;
}) {
  const queryClient = useQueryClient();

  const { mutateAsync: deleteLocation } = useDeleteLocation({
    mutation: {
      onSuccess: () =>
        queryClient.invalidateQueries({ queryKey: getGetLocationsQueryKey() }),
    },
  });

  const openModal = () =>
    openConfirmModal({
      modalId: "delete-location-modal",
      title: (
        <Title component="span" size="sm">
          <Trans>Slett lokasjon</Trans>
        </Title>
      ),
      children: (
        <Text size="sm">
          <Trans>
            Fjerning av plassering <b>{name}</b> har stor innvirkning på mange
            deler av programmet, sørg for at du vet hva du gjør
          </Trans>
        </Text>
      ),
      labels: { confirm: t`Slett`, cancel: t`Avbryt` },
      cancelProps: { variant: "subtle" },
      confirmProps: { color: "red" },
      closeOnConfirm: false,
      onConfirm: async () => {
        await deleteLocation({ id });
        modals.close("delete-location-modal");
      },
    });

  return (
    <Menu.Item color="red" onClick={() => openModal()}>
      <Trans>Slett</Trans>
    </Menu.Item>
  );
}
