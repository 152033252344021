/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */

import {
  Document,
  Font,
  Image,
  Line,
  Link,
  Page,
  StyleSheet,
  Svg,
  Text,
  View,
} from "@react-pdf/renderer";
import dayjs from "dayjs";

import { GetBookingDataStatus } from "@/shared/api/generated/schemas";
import type { GetBookingData } from "@/shared/api/generated/schemas/getBookingData";
import { countryListnbNO } from "@/shared/utils/countryList";
import { langugeListnbNO } from "@/shared/utils/languageList";

import { locationsInfo } from "./locations-meta-info/locations-info";

Font.register({
  family: "Ubuntu",
  fonts: [
    {
      src: "/fonts/ubuntu-v20-latin-regular.ttf",
      fontWeight: 400,
    },
    {
      src: "/fonts/ubuntu-v20-latin-700.ttf",
      fontWeight: 700,
    },
  ],
});

const styles = StyleSheet.create({
  page: {
    padding: 32,
    fontSize: 10,
    fontFamily: "Ubuntu",
    flexDirection: "column",
    gap: 8,
    position: "relative",
  },
  infoLabel: {
    opacity: 0.6,
  },
  infoValue: {
    fontWeight: 700,
  },
  containerBorder: {
    border: "1px solid #ccc",
    padding: 12,
    borderRadius: 5,
  },
  sectionHeader: {
    fontSize: 12,
    marginBottom: 4,
    fontWeight: "bold",
  },
});

interface PDFSummaryProps {
  booking: GetBookingData;
  type: "internal" | "external";
  language: "nb" | "en";
  title: string;
  showPrice: boolean;
}

export const PDFSummary = ({
  booking,
  type,
  language,
  title,
  showPrice,
}: PDFSummaryProps) => {
  const t = getTranslationGetter(language);

  const locationInfo = locationsInfo.find(
    (location) => location.name === booking.location.name,
  );

  return (
    <Document
      title={title}
      author={`Museum Nord - ${booking.location.name}`}
      language={language}
    >
      <Page size="A4" style={styles.page}>
        {booking.status === "cancelled" && (
          <Svg
            width="100%"
            height="100%"
            viewBox="0 0 210 297"
            style={{
              position: "absolute",
              top: 0,
              left: 0,
            }}
          >
            <Line
              x1="2%"
              y1="2%"
              x2="98%"
              y2="98%"
              stroke="#ff8787"
              strokeWidth="3"
            />
          </Svg>
        )}

        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <View
            style={{
              flexDirection: "row",
              gap: 20,
            }}
          >
            <View>
              <Text style={[styles.infoValue, { fontSize: 14 }]}>
                {booking.id}
              </Text>
              <Text style={styles.infoLabel}>{t("bookingNumber")}</Text>
            </View>
            <View>
              <Text style={[styles.infoValue, { fontSize: 14 }]}>
                {dayjs(booking.arrival).format("DD.MM.YYYY")}
              </Text>
              <Text style={styles.infoLabel}>{t("date")}</Text>
            </View>
            <View>
              <Text style={[styles.infoValue, { fontSize: 14 }]}>
                {dayjs(booking.arrival).format("HH:mm")}–
                {dayjs(booking.departure).format("HH:mm")}
              </Text>
              <Text style={styles.infoLabel}>{t("duration")}</Text>
            </View>
          </View>

          <Image
            src={
              new URL(
                `./locations-meta-info/locations-logo/${locationInfo?.logoFilename ?? "museum-nord.png"}`,
                import.meta.url,
              ).href
            }
            style={{ height: 30 }}
          />
        </View>

        {locationInfo && (
          <View>
            <Text>
              {booking.location.name}, {locationInfo.street},{" "}
              {locationInfo.postalCode} {locationInfo.city},{" "}
              <Link src={`tel:${locationInfo.phone.replace(/\s/g, "")}`}>
                {locationInfo.phone}
              </Link>
              ,{" "}
              <Link src={`mailto:${locationInfo.email}`}>
                {locationInfo.email}
              </Link>
            </Text>
          </View>
        )}

        <View
          style={{
            flexDirection: "row",
            gap: 8,
          }}
        >
          <BaseInfo booking={booking} t={t} />
          <GroupInfo booking={booking} t={t} />
        </View>

        <View>
          <Text style={styles.sectionHeader}>{t("program")}</Text>

          <View style={styles.containerBorder}>
            {type === "internal" && (
              <View style={{ marginBottom: 8 }}>
                <Text style={styles.infoValue}>
                  {booking.guide
                    ? `${booking.guide?.firstName} ${booking.guide?.lastName ?? ""}`
                    : "-"}
                </Text>
                <Text style={styles.infoLabel}>{t("guide")}</Text>
              </View>
            )}

            <Program
              booking={booking}
              showPrice={showPrice}
              language={language}
              t={t}
            />
          </View>
        </View>

        <View style={{ flexDirection: "row", gap: 8 }}>
          <View style={{ flex: 1 }}>
            <Text style={styles.sectionHeader}>{t("detailedInformation")}</Text>
            <View style={styles.containerBorder}>
              <Text>{booking.externalInformation}</Text>
            </View>
          </View>

          {type === "internal" && (
            <View style={{ flex: 1 }}>
              <Text style={styles.sectionHeader}>
                {t("internalInformation")}{" "}
                <Text
                  style={{
                    fontWeight: "normal",
                    fontSize: 10,
                  }}
                >
                  ({t("hiddenFromCustomer")})
                </Text>
              </Text>
              <View style={styles.containerBorder}>
                <Text>{booking.internalInformation}</Text>
              </View>
            </View>
          )}
        </View>

        <View>
          <Text>{t("bookingConfirmation")}</Text>
          <Text>
            {t("bookingChangeConditions")}{" "}
            <Link src="https://museumnord.no/vilkar">
              https://museumnord.no/vilkar
            </Link>
          </Text>
        </View>
      </Page>
    </Document>
  );
};

const baseInfoStyles = StyleSheet.create({
  labelColumn: {
    width: 50,
    marginRight: 8,
  },
  valueColumn: {
    flex: 1,
  },
  rowWrapper: {
    flexDirection: "row",
    marginBottom: 8,
    justifyContent: "flex-start",
  },
});

function BaseInfo({
  booking,
  t,
}: {
  booking: GetBookingData;
  t: ReturnType<typeof getTranslationGetter>;
}) {
  return (
    <View
      style={[
        styles.containerBorder,
        {
          flex: 2,
        },
      ]}
    >
      <View style={baseInfoStyles.rowWrapper}>
        <Text style={[styles.infoLabel, baseInfoStyles.labelColumn]}>
          {t("status")}
        </Text>
        <Text
          style={[
            styles.infoValue,
            baseInfoStyles.valueColumn,
            booking.status === "cancelled"
              ? {
                  color: "#fa5252",
                  textTransform: "uppercase",
                }
              : {},
          ]}
        >
          {t(booking.status)}
        </Text>
      </View>
      <View style={baseInfoStyles.rowWrapper}>
        <Text style={[styles.infoLabel, baseInfoStyles.labelColumn]}>
          {t("customer")}
        </Text>
        <Text style={[styles.infoValue, baseInfoStyles.valueColumn]}>
          {booking.customer.name}
        </Text>
      </View>
      <View style={baseInfoStyles.rowWrapper}>
        <Text style={[styles.infoLabel, baseInfoStyles.labelColumn]}>
          {t("visitCategory")}
        </Text>
        <Text style={[styles.infoValue, baseInfoStyles.valueColumn]}>
          {booking.visitCategory}
        </Text>
      </View>
    </View>
  );
}

const groupInfoStyles = StyleSheet.create({
  labelColumn: {
    width: 40,
    marginRight: 8,
  },
  valueColumn: {
    flex: 1,
  },
  rowWrapper: {
    flexDirection: "row",
    marginBottom: 8,
    justifyContent: "flex-start",
  },
});

function GroupInfo({
  booking,
  t,
}: {
  booking: GetBookingData;
  t: ReturnType<typeof getTranslationGetter>;
}) {
  return (
    <View
      style={[
        styles.containerBorder,
        {
          flex: 3,
        },
      ]}
    >
      <View style={groupInfoStyles.rowWrapper}>
        <Text style={[styles.infoLabel, groupInfoStyles.labelColumn]}>
          {t("group")}
        </Text>
        <Text style={[styles.infoValue, groupInfoStyles.valueColumn]}>
          {booking.group?.name ?? "-"}
        </Text>
      </View>
      <View style={groupInfoStyles.rowWrapper}>
        <Text style={[styles.infoLabel, groupInfoStyles.labelColumn]}>
          {t("contact")}
        </Text>
        <Text style={[styles.infoValue, groupInfoStyles.valueColumn]}>
          {booking.group?.contactPerson ?? "-"} / {booking.group?.phone ?? "-"}
        </Text>
      </View>
      <View style={groupInfoStyles.rowWrapper}>
        <Text style={[styles.infoLabel, groupInfoStyles.labelColumn]}>
          {t("numberOfPeople")}
        </Text>
        <Text style={[styles.infoValue, groupInfoStyles.valueColumn]}>
          {booking.group?.guests.booked} + {booking.group?.travelLeader.booked}
        </Text>
      </View>
      <View style={groupInfoStyles.rowWrapper}>
        <Text style={[styles.infoLabel, groupInfoStyles.labelColumn]}>
          {t("nationalities")}
        </Text>
        <Text style={[styles.infoValue, groupInfoStyles.valueColumn]}>
          {booking.group?.nationalities
            ?.map(
              (nationality) =>
                countryListnbNO.find((country) => country.value === nationality)
                  ?.label,
            )
            .join(", ")}
        </Text>
      </View>
      <View style={groupInfoStyles.rowWrapper}>
        <Text style={[styles.infoLabel, groupInfoStyles.labelColumn]}>
          {t("languages")}
        </Text>
        <Text style={[styles.infoValue, groupInfoStyles.valueColumn]}>
          {booking.group?.languages
            ?.map(
              (language) =>
                langugeListnbNO.find((lang) => lang.value === language)?.label,
            )
            .join(" / ")}
        </Text>
      </View>
    </View>
  );
}

const programStyles = StyleSheet.create({
  programRow: {
    flexDirection: "row",
    gap: 2,
    marginBottom: 5,
  },
  productColumn: {
    flex: 1,
  },
  periodColumn: {
    width: 80,
  },
  commentColumn: {
    flex: 1,
  },
  priceColumn: {
    width: 60,
    textAlign: "right",
  },
  amountColumn: {
    width: 40,
    textAlign: "right",
  },
});

function Program({
  booking,
  showPrice,
  language,
  t,
}: {
  booking: GetBookingData;
  showPrice: boolean;
  language: "nb" | "en";
  t: ReturnType<typeof getTranslationGetter>;
}) {
  const totalValue = booking.program?.reduce((acc, programItem) => {
    return acc + Number(programItem.price) * programItem.quantity;
  }, 0);

  return (
    <View>
      <View style={[programStyles.programRow, { fontWeight: 700 }]}>
        <Text style={programStyles.periodColumn}>{t("period")}</Text>
        <Text style={programStyles.productColumn}>{t("product")}</Text>
        <Text style={programStyles.commentColumn}>{t("comment")}</Text>
        {showPrice && (
          <Text style={programStyles.priceColumn}>{t("price")}</Text>
        )}
        <Text style={programStyles.amountColumn}>{t("amount")}</Text>
      </View>

      {booking.program?.map((entry, index) => (
        <View key={index} style={programStyles.programRow}>
          <Text style={programStyles.periodColumn}>
            {entry.start && dayjs(entry.start).format("HH:mm")}-
            {entry.end && dayjs(entry.end).format("HH:mm")}
          </Text>
          <Text style={programStyles.productColumn}>
            {language === "nb"
              ? entry.productNameNO || "-"
              : entry.productNameEN || "-"}
          </Text>
          <Text style={programStyles.commentColumn}>
            {entry.comment || "-"}
          </Text>
          {showPrice && (
            <Text style={programStyles.priceColumn}>{entry.price || 0}</Text>
          )}
          <Text style={programStyles.amountColumn}>{entry.quantity || 0}</Text>
        </View>
      ))}

      {showPrice && (
        <View style={{ marginTop: 8 }}>
          <Text
            style={{
              fontWeight: 700,
              textAlign: "right",
              marginRight: programStyles.amountColumn.width - 23,
            }}
          >
            {t("total")} {totalValue} {t("currency")}
          </Text>
        </View>
      )}
    </View>
  );
}

function getTranslationGetter(language: "nb" | "en") {
  return (key: keyof (typeof translations)[typeof language]) =>
    translations[language][key];
}

const translations = {
  nb: {
    bookingNumber: "Bookingnr.",
    date: "Dato",
    duration: "Varighet",
    status: "Status",
    customer: "Kunde",
    visitCategory: "Kategori",
    group: "Gruppe",
    contact: "Kontakt",
    numberOfPeople: "Antall",
    nationalities: "Nasj.",
    languages: "Språk",
    program: "Program",
    guide: "Guide",
    product: "Produkt",
    period: "Periode",
    comment: "Kommentar",
    price: "Pris",
    amount: "Antall",
    detailedInformation: "Detaljert informasjon",
    internalInformation: "Intern informasjon",
    hiddenFromCustomer: "ikke synlig på kopi til kunde",
    total: "Total",
    currency: "NOK",
    bookingConfirmation:
      "Bestillingen må bekreftes skriftlig senest 14 dager fra ordrebekreftelse er mottatt.",
    bookingChangeConditions: "Les vilkår for endring av bestilling her:",
    // booking statuses
    cancelled: "Avlyst",
    request: "Ønskes",
    reserved: "Reservert",
    confirmed: "Bekreftet",
    confirmationsent: "Bekreftelse sendt",
    arrived: "Ankommet",
  },
  en: {
    bookingNumber: "Bookingnr",
    date: "Date",
    duration: "Duration",
    status: "Status",
    customer: "Customer",
    visitCategory: "Category",
    group: "Group",
    contact: "Contact",
    numberOfPeople: "Number",
    nationalities: "Nat.",
    languages: "Lang.",
    program: "Program",
    guide: "Guide",
    product: "Product",
    period: "Period",
    comment: "Comment",
    price: "Price",
    amount: "Amount",
    detailedInformation: "Detailed information",
    internalInformation: "Internal information",
    hiddenFromCustomer: "not visible to customer",
    total: "Total",
    currency: "NOK",
    bookingConfirmation:
      "The order must be confirmed in writing no later than 14 days from the order confirmation is received.",
    bookingChangeConditions: "Read the conditions for changing a booking here:",
    // booking statuses
    cancelled: "Cancelled",
    request: "Requested",
    reserved: "Reserved",
    confirmed: "Confirmed",
    confirmationsent: "Confirmation sent",
    arrived: "Arrived",
  },
} satisfies Record<
  "nb" | "en",
  Record<GetBookingDataStatus | (string & {}), string>
>;
