import { t, Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import {
  Autocomplete,
  Button,
  Group,
  Select,
  Stack,
  Text,
} from "@mantine/core";
import { DateInput, TimeInput } from "@mantine/dates";
import { useForm, zodResolver } from "@mantine/form";
import { useNavigate } from "@tanstack/react-router";
import dayjs from "dayjs";
import { z } from "zod";

import { useCreateBooking } from "@/shared/api/generated/booking";
import {
  useCreateCustomer,
  useGetCustomers,
} from "@/shared/api/generated/customers";
import { useGetLocations } from "@/shared/api/generated/locations";
import {
  CreateBookingRequestPaymentCategory,
  CreateBookingRequestPaymentCategoryDrinks,
  CreateBookingRequestVisitCategory,
} from "@/shared/api/generated/schemas";
import { nullableInput } from "@/shared/utils/forms/nullableInput";

import { createBookingPaymentFormDrinksOptions } from "./utils/create-booking-payment-drinks";
import { createBookingPaymentFormOptions } from "./utils/create-booking-payment-form";
import { createBookingVisitCategoryOptions } from "./utils/create-booking-visit-category";

const FormSchema = z
  .object({
    location: nullableInput(
      z.string().min(1, { message: t`Velg en lokasjon` }),
      t`Velg en lokasjon`,
    ),
    date: nullableInput(z.date({ message: t`Velg en dato` }), t`Velg en dato`),
    timeStart: z.string(),
    timeEnd: z.string(),
    customer: nullableInput(
      z.string().min(1, { message: t`Velg kunde` }),
      t`Velg kunde`,
    ),
    visitCategory: z
      .nativeEnum(CreateBookingRequestVisitCategory)
      .nullable()
      .optional(),
    paymentForm: z
      .nativeEnum(CreateBookingRequestPaymentCategory)
      .nullable()
      .optional(),
    paymentFormDrinks: z
      .nativeEnum(CreateBookingRequestPaymentCategoryDrinks)
      .nullable()
      .optional(),
  })
  .refine(
    (data) => {
      const start = dayjs(data.timeStart, "HH:mm");
      const end = dayjs(data.timeEnd, "HH:mm");

      if (start.isValid() && end.isValid()) {
        return end.isAfter(start);
      }
      return true;
    },
    {
      message: t`Slutt må være etter start`,
      path: ["timeEnd"],
    },
  );

type FormInitial = z.input<typeof FormSchema>;
type FormSubmit = z.output<typeof FormSchema>;

interface Props {
  close: () => void;
}

export default function CreateBookingForm({ close }: Props) {
  const navigate = useNavigate();

  const { i18n } = useLingui();

  const { mutateAsync: createBooking } = useCreateBooking();

  const form = useForm<FormInitial, (v: FormInitial) => FormSubmit>({
    mode: "uncontrolled",
    initialValues: {
      location: null,
      date: null,
      timeStart: "",
      timeEnd: "",
      customer: null,
      visitCategory: null,
      paymentForm: null,
      paymentFormDrinks: null,
    },
    validate: zodResolver(FormSchema),
  });

  const { data: customers } = useGetCustomers();
  const customerOptions = customers?.data?.map((c) => c.name);

  const { mutateAsync: createCustomer } = useCreateCustomer();

  async function handleSubmit(values: FormSubmit) {
    let arrival = dayjs(values.date).startOf("day");
    let departure = dayjs(values.date).endOf("day");

    if (values.timeStart) {
      const startTime = dayjs(values.timeStart, "HH:mm");
      arrival = dayjs(values.date)
        .hour(startTime.hour())
        .minute(startTime.minute());
    }

    if (values.timeEnd) {
      const endTime = dayjs(values.timeEnd, "HH:mm");
      departure = dayjs(values.date)
        .hour(endTime.hour())
        .minute(endTime.minute());
    }

    let customerId = customers?.data?.find(
      (c) => c.name === values.customer,
    )?.id;

    if (!customerId) {
      const newCustomer = await createCustomer({
        data: {
          name: values.customer,
        },
      });
      customerId = newCustomer.data.id;
    }

    await createBooking(
      {
        data: {
          locationId: values.location,
          arrival: arrival.toISOString(),
          departure: departure.toISOString(),
          customerId: customerId,
          status: "reserved",
          visitCategory: values.visitCategory,
          paymentCategory: values.paymentForm,
          paymentCategoryDrinks: values.paymentFormDrinks,
        },
      },
      {
        onSuccess: (response) => {
          const bookingId = response.data.id;
          void navigate({
            to: `/bookings/$bookingId`,
            params: { bookingId: bookingId.toString() },
          });
          close();
        },
      },
    );
  }

  const { data: locations } = useGetLocations();

  return (
    <form onSubmit={form.onSubmit(handleSubmit)}>
      <Text size="sm" c="dimmed" mb={8}>
        <Trans>
          Fyll ut den informasjonen du har tilgjengelig. Alle felter kan endres
          i ettertid.
        </Trans>
      </Text>

      <Stack>
        <Select
          label={t`Lokasjon`}
          placeholder={t`Velg lokasjon`}
          data={locations?.data?.map((location) => ({
            value: location.id,
            label: location.name,
          }))}
          searchable
          clearable
          withAsterisk
          {...form.getInputProps("location")}
        />

        <Group grow align="flex-start">
          <DateInput
            label={t`Ankomstdato`}
            placeholder={dayjs.localeData().longDateFormat("L")}
            withAsterisk
            clearable
            {...form.getInputProps("date")}
            key={form.key("date")}
          />

          <Group grow align="flex-start">
            <TimeInput
              label={t`Ankomst`}
              {...form.getInputProps("timeStart")}
            />
            <TimeInput label={t`Avreise`} {...form.getInputProps("timeEnd")} />
          </Group>
        </Group>

        <Group grow align="flex-start">
          <Autocomplete
            label={t`Kunde`}
            placeholder={t`Velg kunde`}
            data={customerOptions}
            withAsterisk
            {...form.getInputProps("customer")}
          />
          <Select
            label={t`Besøkskategori`}
            placeholder={t`Velg besøkskategori`}
            data={createBookingVisitCategoryOptions(i18n)}
            searchable
            clearable
            {...form.getInputProps("visitCategory")}
          />
        </Group>

        <Group grow align="flex-start">
          <Select
            label={t`Betalingsform`}
            placeholder={t`Velg betalingsform`}
            data={createBookingPaymentFormOptions(i18n)}
            searchable
            clearable
            {...form.getInputProps("paymentForm")}
          />
        </Group>

        <Group grow align="flex-start">
          <Select
            label={t`Betalingsform drikke`}
            placeholder={t`Velg betalingsform drikke`}
            data={createBookingPaymentFormDrinksOptions(i18n)}
            searchable
            clearable
            {...form.getInputProps("paymentFormDrinks")}
          />
        </Group>

        <Group justify="end" gap={8} mt={8}>
          <Button variant="subtle" onClick={close}>
            <Trans>Avbryt</Trans>
          </Button>
          <Button type="submit">
            <Trans>Opprett</Trans>
          </Button>
        </Group>
      </Stack>
    </form>
  );
}
