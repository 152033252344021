import { SignIn } from "@clerk/clerk-react";
import { Center } from "@mantine/core";
import { createFileRoute, redirect } from "@tanstack/react-router";
import { z } from "zod";

export const Route = createFileRoute("/login/$")({
  validateSearch: z.object({
    redirect: z.string().optional().catch(""),
  }),
  beforeLoad: ({ context }) => {
    if (context.auth.isSignedIn) {
      redirect({
        to: "/",
        throw: true,
      });
    }
  },
  component: Login,
});

function Login() {
  const search = Route.useSearch();

  return (
    <Center h="60vh">
      <SignIn
        routing="path"
        path="/login"
        signUpUrl="/sign-up"
        fallbackRedirectUrl={search.redirect}
      />
    </Center>
  );
}
