import { Trans } from "@lingui/macro";
import { Box, Flex } from "@mantine/core";
import { createFileRoute } from "@tanstack/react-router";

import CreateEmployee from "@/features/employees/create-employee/crete-employee-modal";
import EmployeesTable from "@/features/employees/employees-table";

export const Route = createFileRoute("/_auth-layout/employees/")({
  component: () => <EmployeesPage />,
});

function EmployeesPage() {
  return (
    <Box p="lg">
      <Flex align="center" justify="space-between">
        <h1>
          <Trans>Personell</Trans>
        </h1>

        <CreateEmployee />
      </Flex>

      <EmployeesTable />
    </Box>
  );
}
