import React from "react";
import * as Sentry from "@sentry/react";

export default function ErrorBoundary({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <Sentry.ErrorBoundary fallback={ErrorFallback} showDialog>
      {children}
    </Sentry.ErrorBoundary>
  );
}

const ErrorFallback: Sentry.FallbackRender = ({
  // error,
  // componentStack,
  // eslint-disable-next-line @typescript-eslint/unbound-method
  resetError,
}) => {
  return (
    <div>
      <p>Something went wrong:</p>
      <button onClick={resetError}>Try again</button>
    </div>
  );
};
