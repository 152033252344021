/**
 * Generated by orval v7.1.1 🍺
 * Do not edit manually.
 * Template API
 * Something something. <br><br>The spec is available at <a href='/swagger/api.json'>/swagger/api.json</a>.
 * OpenAPI spec version: 0
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import {
  useCallback
} from 'react'
import type {
  ApiAppError,
  CreateCategoryRequest,
  CreateCategoryResponse,
  GetCategoriesResponse,
  GetCategoryResponse,
  UpdateCategoryRequest,
  UpdateCategoryResponse
} from './schemas'
import { useApi } from '../client';
import type { ErrorType, BodyType } from '../client';



type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


/**
 * @summary Get Categories
 */
export const useGetCategoriesHook = () => {
        const getCategories = useApi<GetCategoriesResponse>();

        return useCallback((
    
 options?: SecondParameter<ReturnType<typeof useApi>>,signal?: AbortSignal
) => {
        return getCategories(
          {url: `/categories`, method: 'GET', signal
    },
          options);
        }, [getCategories])
      }
    

export const getGetCategoriesQueryKey = () => {
    return [`/categories`] as const;
    }

    
export const useGetCategoriesQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetCategoriesHook>>>, TError = ErrorType<ApiAppError>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetCategoriesHook>>>, TError, TData>>, request?: SecondParameter<ReturnType<typeof useApi>>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCategoriesQueryKey();

  const getCategories =  useGetCategoriesHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetCategoriesHook>>>> = ({ signal }) => getCategories(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetCategoriesHook>>>, TError, TData> & { queryKey: QueryKey }
}

export type GetCategoriesQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetCategoriesHook>>>>
export type GetCategoriesQueryError = ErrorType<ApiAppError>


export function useGetCategories<TData = Awaited<ReturnType<ReturnType<typeof useGetCategoriesHook>>>, TError = ErrorType<ApiAppError>>(
  options: { query:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetCategoriesHook>>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<ReturnType<typeof useGetCategoriesHook>>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<ReturnType<typeof useApi>>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetCategories<TData = Awaited<ReturnType<ReturnType<typeof useGetCategoriesHook>>>, TError = ErrorType<ApiAppError>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetCategoriesHook>>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<ReturnType<typeof useGetCategoriesHook>>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<ReturnType<typeof useApi>>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetCategories<TData = Awaited<ReturnType<ReturnType<typeof useGetCategoriesHook>>>, TError = ErrorType<ApiAppError>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetCategoriesHook>>>, TError, TData>>, request?: SecondParameter<ReturnType<typeof useApi>>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Get Categories
 */

export function useGetCategories<TData = Awaited<ReturnType<ReturnType<typeof useGetCategoriesHook>>>, TError = ErrorType<ApiAppError>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetCategoriesHook>>>, TError, TData>>, request?: SecondParameter<ReturnType<typeof useApi>>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = useGetCategoriesQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Create Category
 */
export const useCreateCategoryHook = () => {
        const createCategory = useApi<CreateCategoryResponse>();

        return useCallback((
    createCategoryRequest: BodyType<CreateCategoryRequest>,
 options?: SecondParameter<ReturnType<typeof useApi>>,) => {
        return createCategory(
          {url: `/category`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: createCategoryRequest
    },
          options);
        }, [createCategory])
      }
    


export const useCreateCategoryMutationOptions = <TError = ErrorType<ApiAppError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCreateCategoryHook>>>, TError,{data: BodyType<CreateCategoryRequest>}, TContext>, request?: SecondParameter<ReturnType<typeof useApi>>}
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCreateCategoryHook>>>, TError,{data: BodyType<CreateCategoryRequest>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      const createCategory =  useCreateCategoryHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useCreateCategoryHook>>>, {data: BodyType<CreateCategoryRequest>}> = (props) => {
          const {data} = props ?? {};

          return  createCategory(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CreateCategoryMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useCreateCategoryHook>>>>
    export type CreateCategoryMutationBody = BodyType<CreateCategoryRequest>
    export type CreateCategoryMutationError = ErrorType<ApiAppError>

    /**
 * @summary Create Category
 */
export const useCreateCategory = <TError = ErrorType<ApiAppError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCreateCategoryHook>>>, TError,{data: BodyType<CreateCategoryRequest>}, TContext>, request?: SecondParameter<ReturnType<typeof useApi>>}
): UseMutationResult<
        Awaited<ReturnType<ReturnType<typeof useCreateCategoryHook>>>,
        TError,
        {data: BodyType<CreateCategoryRequest>},
        TContext
      > => {

      const mutationOptions = useCreateCategoryMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Delete Category
 */
export const useDeleteCategoryHook = () => {
        const deleteCategory = useApi<void>();

        return useCallback((
    id: string,
 options?: SecondParameter<ReturnType<typeof useApi>>,) => {
        return deleteCategory(
          {url: `/category/${id}`, method: 'DELETE'
    },
          options);
        }, [deleteCategory])
      }
    


export const useDeleteCategoryMutationOptions = <TError = ErrorType<ApiAppError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteCategoryHook>>>, TError,{id: string}, TContext>, request?: SecondParameter<ReturnType<typeof useApi>>}
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteCategoryHook>>>, TError,{id: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      const deleteCategory =  useDeleteCategoryHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useDeleteCategoryHook>>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  deleteCategory(id,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteCategoryMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useDeleteCategoryHook>>>>
    
    export type DeleteCategoryMutationError = ErrorType<ApiAppError>

    /**
 * @summary Delete Category
 */
export const useDeleteCategory = <TError = ErrorType<ApiAppError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteCategoryHook>>>, TError,{id: string}, TContext>, request?: SecondParameter<ReturnType<typeof useApi>>}
): UseMutationResult<
        Awaited<ReturnType<ReturnType<typeof useDeleteCategoryHook>>>,
        TError,
        {id: string},
        TContext
      > => {

      const mutationOptions = useDeleteCategoryMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Get Category
 */
export const useGetCategoryHook = () => {
        const getCategory = useApi<GetCategoryResponse>();

        return useCallback((
    id: string,
 options?: SecondParameter<ReturnType<typeof useApi>>,signal?: AbortSignal
) => {
        return getCategory(
          {url: `/category/${id}`, method: 'GET', signal
    },
          options);
        }, [getCategory])
      }
    

export const getGetCategoryQueryKey = (id: string,) => {
    return [`/category/${id}`] as const;
    }

    
export const useGetCategoryQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetCategoryHook>>>, TError = ErrorType<ApiAppError>>(id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetCategoryHook>>>, TError, TData>>, request?: SecondParameter<ReturnType<typeof useApi>>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCategoryQueryKey(id);

  const getCategory =  useGetCategoryHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetCategoryHook>>>> = ({ signal }) => getCategory(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetCategoryHook>>>, TError, TData> & { queryKey: QueryKey }
}

export type GetCategoryQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetCategoryHook>>>>
export type GetCategoryQueryError = ErrorType<ApiAppError>


export function useGetCategory<TData = Awaited<ReturnType<ReturnType<typeof useGetCategoryHook>>>, TError = ErrorType<ApiAppError>>(
 id: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetCategoryHook>>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<ReturnType<typeof useGetCategoryHook>>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<ReturnType<typeof useApi>>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetCategory<TData = Awaited<ReturnType<ReturnType<typeof useGetCategoryHook>>>, TError = ErrorType<ApiAppError>>(
 id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetCategoryHook>>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<ReturnType<typeof useGetCategoryHook>>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<ReturnType<typeof useApi>>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetCategory<TData = Awaited<ReturnType<ReturnType<typeof useGetCategoryHook>>>, TError = ErrorType<ApiAppError>>(
 id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetCategoryHook>>>, TError, TData>>, request?: SecondParameter<ReturnType<typeof useApi>>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Get Category
 */

export function useGetCategory<TData = Awaited<ReturnType<ReturnType<typeof useGetCategoryHook>>>, TError = ErrorType<ApiAppError>>(
 id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetCategoryHook>>>, TError, TData>>, request?: SecondParameter<ReturnType<typeof useApi>>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = useGetCategoryQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Update Category
 */
export const useUpdateCategoryHook = () => {
        const updateCategory = useApi<UpdateCategoryResponse>();

        return useCallback((
    id: string,
    updateCategoryRequest: BodyType<UpdateCategoryRequest>,
 options?: SecondParameter<ReturnType<typeof useApi>>,) => {
        return updateCategory(
          {url: `/category/${id}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: updateCategoryRequest
    },
          options);
        }, [updateCategory])
      }
    


export const useUpdateCategoryMutationOptions = <TError = ErrorType<ApiAppError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateCategoryHook>>>, TError,{id: string;data: BodyType<UpdateCategoryRequest>}, TContext>, request?: SecondParameter<ReturnType<typeof useApi>>}
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateCategoryHook>>>, TError,{id: string;data: BodyType<UpdateCategoryRequest>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      const updateCategory =  useUpdateCategoryHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useUpdateCategoryHook>>>, {id: string;data: BodyType<UpdateCategoryRequest>}> = (props) => {
          const {id,data} = props ?? {};

          return  updateCategory(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateCategoryMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useUpdateCategoryHook>>>>
    export type UpdateCategoryMutationBody = BodyType<UpdateCategoryRequest>
    export type UpdateCategoryMutationError = ErrorType<ApiAppError>

    /**
 * @summary Update Category
 */
export const useUpdateCategory = <TError = ErrorType<ApiAppError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateCategoryHook>>>, TError,{id: string;data: BodyType<UpdateCategoryRequest>}, TContext>, request?: SecondParameter<ReturnType<typeof useApi>>}
): UseMutationResult<
        Awaited<ReturnType<ReturnType<typeof useUpdateCategoryHook>>>,
        TError,
        {id: string;data: BodyType<UpdateCategoryRequest>},
        TContext
      > => {

      const mutationOptions = useUpdateCategoryMutationOptions(options);

      return useMutation(mutationOptions);
    }
    