/**
 * Generated by orval v7.1.1 🍺
 * Do not edit manually.
 * Template API
 * Something something. <br><br>The spec is available at <a href='/swagger/api.json'>/swagger/api.json</a>.
 * OpenAPI spec version: 0
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import {
  useCallback
} from 'react'
import type {
  ApiAppError,
  CreateBookingRequest,
  CreateBookingResponse,
  GetBookingResponse,
  GetBookingsParams,
  GetBookingsResponse,
  UpdateBookingChangeStatusRequest,
  UpdateBookingGroupInformationRequest,
  UpdateBookingResponse,
  UpdateBookingSendExternalBookingRequest,
  UpdateBookingSendInternalBookingRequest,
  UpdateBookingUpdateDetailedInformationRequest,
  UpdateBookingUpdateProgramRequest,
  UpdateBookingUpdateVisitInformationRequest
} from './schemas'
import { useApi } from '../client';
import type { ErrorType, BodyType } from '../client';



type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


/**
 * @summary Create Booking
 */
export const useCreateBookingHook = () => {
        const createBooking = useApi<CreateBookingResponse>();

        return useCallback((
    createBookingRequest: BodyType<CreateBookingRequest>,
 options?: SecondParameter<ReturnType<typeof useApi>>,) => {
        return createBooking(
          {url: `/booking`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: createBookingRequest
    },
          options);
        }, [createBooking])
      }
    


export const useCreateBookingMutationOptions = <TError = ErrorType<ApiAppError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCreateBookingHook>>>, TError,{data: BodyType<CreateBookingRequest>}, TContext>, request?: SecondParameter<ReturnType<typeof useApi>>}
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCreateBookingHook>>>, TError,{data: BodyType<CreateBookingRequest>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      const createBooking =  useCreateBookingHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useCreateBookingHook>>>, {data: BodyType<CreateBookingRequest>}> = (props) => {
          const {data} = props ?? {};

          return  createBooking(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CreateBookingMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useCreateBookingHook>>>>
    export type CreateBookingMutationBody = BodyType<CreateBookingRequest>
    export type CreateBookingMutationError = ErrorType<ApiAppError>

    /**
 * @summary Create Booking
 */
export const useCreateBooking = <TError = ErrorType<ApiAppError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCreateBookingHook>>>, TError,{data: BodyType<CreateBookingRequest>}, TContext>, request?: SecondParameter<ReturnType<typeof useApi>>}
): UseMutationResult<
        Awaited<ReturnType<ReturnType<typeof useCreateBookingHook>>>,
        TError,
        {data: BodyType<CreateBookingRequest>},
        TContext
      > => {

      const mutationOptions = useCreateBookingMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Get Booking
 */
export const useGetBookingHook = () => {
        const getBooking = useApi<GetBookingResponse>();

        return useCallback((
    id: number,
 options?: SecondParameter<ReturnType<typeof useApi>>,signal?: AbortSignal
) => {
        return getBooking(
          {url: `/booking/${id}`, method: 'GET', signal
    },
          options);
        }, [getBooking])
      }
    

export const getGetBookingQueryKey = (id: number,) => {
    return [`/booking/${id}`] as const;
    }

    
export const useGetBookingQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetBookingHook>>>, TError = ErrorType<ApiAppError>>(id: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetBookingHook>>>, TError, TData>>, request?: SecondParameter<ReturnType<typeof useApi>>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetBookingQueryKey(id);

  const getBooking =  useGetBookingHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetBookingHook>>>> = ({ signal }) => getBooking(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetBookingHook>>>, TError, TData> & { queryKey: QueryKey }
}

export type GetBookingQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetBookingHook>>>>
export type GetBookingQueryError = ErrorType<ApiAppError>


export function useGetBooking<TData = Awaited<ReturnType<ReturnType<typeof useGetBookingHook>>>, TError = ErrorType<ApiAppError>>(
 id: number, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetBookingHook>>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<ReturnType<typeof useGetBookingHook>>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<ReturnType<typeof useApi>>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetBooking<TData = Awaited<ReturnType<ReturnType<typeof useGetBookingHook>>>, TError = ErrorType<ApiAppError>>(
 id: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetBookingHook>>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<ReturnType<typeof useGetBookingHook>>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<ReturnType<typeof useApi>>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetBooking<TData = Awaited<ReturnType<ReturnType<typeof useGetBookingHook>>>, TError = ErrorType<ApiAppError>>(
 id: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetBookingHook>>>, TError, TData>>, request?: SecondParameter<ReturnType<typeof useApi>>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Get Booking
 */

export function useGetBooking<TData = Awaited<ReturnType<ReturnType<typeof useGetBookingHook>>>, TError = ErrorType<ApiAppError>>(
 id: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetBookingHook>>>, TError, TData>>, request?: SecondParameter<ReturnType<typeof useApi>>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = useGetBookingQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Update Booking Detailed Information
 */
export const useUpdateBookingDetailedInformationHook = () => {
        const updateBookingDetailedInformation = useApi<UpdateBookingResponse>();

        return useCallback((
    id: number,
    updateBookingUpdateDetailedInformationRequest: BodyType<UpdateBookingUpdateDetailedInformationRequest>,
 options?: SecondParameter<ReturnType<typeof useApi>>,) => {
        return updateBookingDetailedInformation(
          {url: `/booking/${id}/detailed_information`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: updateBookingUpdateDetailedInformationRequest
    },
          options);
        }, [updateBookingDetailedInformation])
      }
    


export const useUpdateBookingDetailedInformationMutationOptions = <TError = ErrorType<ApiAppError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateBookingDetailedInformationHook>>>, TError,{id: number;data: BodyType<UpdateBookingUpdateDetailedInformationRequest>}, TContext>, request?: SecondParameter<ReturnType<typeof useApi>>}
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateBookingDetailedInformationHook>>>, TError,{id: number;data: BodyType<UpdateBookingUpdateDetailedInformationRequest>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      const updateBookingDetailedInformation =  useUpdateBookingDetailedInformationHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useUpdateBookingDetailedInformationHook>>>, {id: number;data: BodyType<UpdateBookingUpdateDetailedInformationRequest>}> = (props) => {
          const {id,data} = props ?? {};

          return  updateBookingDetailedInformation(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateBookingDetailedInformationMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useUpdateBookingDetailedInformationHook>>>>
    export type UpdateBookingDetailedInformationMutationBody = BodyType<UpdateBookingUpdateDetailedInformationRequest>
    export type UpdateBookingDetailedInformationMutationError = ErrorType<ApiAppError>

    /**
 * @summary Update Booking Detailed Information
 */
export const useUpdateBookingDetailedInformation = <TError = ErrorType<ApiAppError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateBookingDetailedInformationHook>>>, TError,{id: number;data: BodyType<UpdateBookingUpdateDetailedInformationRequest>}, TContext>, request?: SecondParameter<ReturnType<typeof useApi>>}
): UseMutationResult<
        Awaited<ReturnType<ReturnType<typeof useUpdateBookingDetailedInformationHook>>>,
        TError,
        {id: number;data: BodyType<UpdateBookingUpdateDetailedInformationRequest>},
        TContext
      > => {

      const mutationOptions = useUpdateBookingDetailedInformationMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Update Booking Group Information
 */
export const useUpdateBookingGroupInformationHook = () => {
        const updateBookingGroupInformation = useApi<UpdateBookingResponse>();

        return useCallback((
    id: number,
    updateBookingGroupInformationRequest: BodyType<UpdateBookingGroupInformationRequest>,
 options?: SecondParameter<ReturnType<typeof useApi>>,) => {
        return updateBookingGroupInformation(
          {url: `/booking/${id}/group_information`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: updateBookingGroupInformationRequest
    },
          options);
        }, [updateBookingGroupInformation])
      }
    


export const useUpdateBookingGroupInformationMutationOptions = <TError = ErrorType<ApiAppError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateBookingGroupInformationHook>>>, TError,{id: number;data: BodyType<UpdateBookingGroupInformationRequest>}, TContext>, request?: SecondParameter<ReturnType<typeof useApi>>}
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateBookingGroupInformationHook>>>, TError,{id: number;data: BodyType<UpdateBookingGroupInformationRequest>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      const updateBookingGroupInformation =  useUpdateBookingGroupInformationHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useUpdateBookingGroupInformationHook>>>, {id: number;data: BodyType<UpdateBookingGroupInformationRequest>}> = (props) => {
          const {id,data} = props ?? {};

          return  updateBookingGroupInformation(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateBookingGroupInformationMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useUpdateBookingGroupInformationHook>>>>
    export type UpdateBookingGroupInformationMutationBody = BodyType<UpdateBookingGroupInformationRequest>
    export type UpdateBookingGroupInformationMutationError = ErrorType<ApiAppError>

    /**
 * @summary Update Booking Group Information
 */
export const useUpdateBookingGroupInformation = <TError = ErrorType<ApiAppError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateBookingGroupInformationHook>>>, TError,{id: number;data: BodyType<UpdateBookingGroupInformationRequest>}, TContext>, request?: SecondParameter<ReturnType<typeof useApi>>}
): UseMutationResult<
        Awaited<ReturnType<ReturnType<typeof useUpdateBookingGroupInformationHook>>>,
        TError,
        {id: number;data: BodyType<UpdateBookingGroupInformationRequest>},
        TContext
      > => {

      const mutationOptions = useUpdateBookingGroupInformationMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Update Booking Program
 */
export const useUpdateBookingProgramHook = () => {
        const updateBookingProgram = useApi<UpdateBookingResponse>();

        return useCallback((
    id: number,
    updateBookingUpdateProgramRequest: BodyType<UpdateBookingUpdateProgramRequest>,
 options?: SecondParameter<ReturnType<typeof useApi>>,) => {
        return updateBookingProgram(
          {url: `/booking/${id}/program`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: updateBookingUpdateProgramRequest
    },
          options);
        }, [updateBookingProgram])
      }
    


export const useUpdateBookingProgramMutationOptions = <TError = ErrorType<ApiAppError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateBookingProgramHook>>>, TError,{id: number;data: BodyType<UpdateBookingUpdateProgramRequest>}, TContext>, request?: SecondParameter<ReturnType<typeof useApi>>}
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateBookingProgramHook>>>, TError,{id: number;data: BodyType<UpdateBookingUpdateProgramRequest>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      const updateBookingProgram =  useUpdateBookingProgramHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useUpdateBookingProgramHook>>>, {id: number;data: BodyType<UpdateBookingUpdateProgramRequest>}> = (props) => {
          const {id,data} = props ?? {};

          return  updateBookingProgram(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateBookingProgramMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useUpdateBookingProgramHook>>>>
    export type UpdateBookingProgramMutationBody = BodyType<UpdateBookingUpdateProgramRequest>
    export type UpdateBookingProgramMutationError = ErrorType<ApiAppError>

    /**
 * @summary Update Booking Program
 */
export const useUpdateBookingProgram = <TError = ErrorType<ApiAppError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateBookingProgramHook>>>, TError,{id: number;data: BodyType<UpdateBookingUpdateProgramRequest>}, TContext>, request?: SecondParameter<ReturnType<typeof useApi>>}
): UseMutationResult<
        Awaited<ReturnType<ReturnType<typeof useUpdateBookingProgramHook>>>,
        TError,
        {id: number;data: BodyType<UpdateBookingUpdateProgramRequest>},
        TContext
      > => {

      const mutationOptions = useUpdateBookingProgramMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Send Booking Externally
 */
export const useSendBookingExternallyHook = () => {
        const sendBookingExternally = useApi<UpdateBookingResponse>();

        return useCallback((
    id: number,
    updateBookingSendExternalBookingRequest: BodyType<UpdateBookingSendExternalBookingRequest>,
 options?: SecondParameter<ReturnType<typeof useApi>>,) => {
        return sendBookingExternally(
          {url: `/booking/${id}/send/externally`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: updateBookingSendExternalBookingRequest
    },
          options);
        }, [sendBookingExternally])
      }
    


export const useSendBookingExternallyMutationOptions = <TError = ErrorType<ApiAppError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useSendBookingExternallyHook>>>, TError,{id: number;data: BodyType<UpdateBookingSendExternalBookingRequest>}, TContext>, request?: SecondParameter<ReturnType<typeof useApi>>}
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useSendBookingExternallyHook>>>, TError,{id: number;data: BodyType<UpdateBookingSendExternalBookingRequest>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      const sendBookingExternally =  useSendBookingExternallyHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useSendBookingExternallyHook>>>, {id: number;data: BodyType<UpdateBookingSendExternalBookingRequest>}> = (props) => {
          const {id,data} = props ?? {};

          return  sendBookingExternally(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type SendBookingExternallyMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useSendBookingExternallyHook>>>>
    export type SendBookingExternallyMutationBody = BodyType<UpdateBookingSendExternalBookingRequest>
    export type SendBookingExternallyMutationError = ErrorType<ApiAppError>

    /**
 * @summary Send Booking Externally
 */
export const useSendBookingExternally = <TError = ErrorType<ApiAppError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useSendBookingExternallyHook>>>, TError,{id: number;data: BodyType<UpdateBookingSendExternalBookingRequest>}, TContext>, request?: SecondParameter<ReturnType<typeof useApi>>}
): UseMutationResult<
        Awaited<ReturnType<ReturnType<typeof useSendBookingExternallyHook>>>,
        TError,
        {id: number;data: BodyType<UpdateBookingSendExternalBookingRequest>},
        TContext
      > => {

      const mutationOptions = useSendBookingExternallyMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Send Booking Internally
 */
export const useSendBookingInternallyHook = () => {
        const sendBookingInternally = useApi<UpdateBookingResponse>();

        return useCallback((
    id: number,
    updateBookingSendInternalBookingRequest: BodyType<UpdateBookingSendInternalBookingRequest>,
 options?: SecondParameter<ReturnType<typeof useApi>>,) => {
        return sendBookingInternally(
          {url: `/booking/${id}/send/internally`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: updateBookingSendInternalBookingRequest
    },
          options);
        }, [sendBookingInternally])
      }
    


export const useSendBookingInternallyMutationOptions = <TError = ErrorType<ApiAppError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useSendBookingInternallyHook>>>, TError,{id: number;data: BodyType<UpdateBookingSendInternalBookingRequest>}, TContext>, request?: SecondParameter<ReturnType<typeof useApi>>}
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useSendBookingInternallyHook>>>, TError,{id: number;data: BodyType<UpdateBookingSendInternalBookingRequest>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      const sendBookingInternally =  useSendBookingInternallyHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useSendBookingInternallyHook>>>, {id: number;data: BodyType<UpdateBookingSendInternalBookingRequest>}> = (props) => {
          const {id,data} = props ?? {};

          return  sendBookingInternally(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type SendBookingInternallyMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useSendBookingInternallyHook>>>>
    export type SendBookingInternallyMutationBody = BodyType<UpdateBookingSendInternalBookingRequest>
    export type SendBookingInternallyMutationError = ErrorType<ApiAppError>

    /**
 * @summary Send Booking Internally
 */
export const useSendBookingInternally = <TError = ErrorType<ApiAppError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useSendBookingInternallyHook>>>, TError,{id: number;data: BodyType<UpdateBookingSendInternalBookingRequest>}, TContext>, request?: SecondParameter<ReturnType<typeof useApi>>}
): UseMutationResult<
        Awaited<ReturnType<ReturnType<typeof useSendBookingInternallyHook>>>,
        TError,
        {id: number;data: BodyType<UpdateBookingSendInternalBookingRequest>},
        TContext
      > => {

      const mutationOptions = useSendBookingInternallyMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Change Booking Status
 */
export const useChangeBookingStatusHook = () => {
        const changeBookingStatus = useApi<UpdateBookingResponse>();

        return useCallback((
    id: number,
    updateBookingChangeStatusRequest: BodyType<UpdateBookingChangeStatusRequest>,
 options?: SecondParameter<ReturnType<typeof useApi>>,) => {
        return changeBookingStatus(
          {url: `/booking/${id}/status`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: updateBookingChangeStatusRequest
    },
          options);
        }, [changeBookingStatus])
      }
    


export const useChangeBookingStatusMutationOptions = <TError = ErrorType<ApiAppError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useChangeBookingStatusHook>>>, TError,{id: number;data: BodyType<UpdateBookingChangeStatusRequest>}, TContext>, request?: SecondParameter<ReturnType<typeof useApi>>}
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useChangeBookingStatusHook>>>, TError,{id: number;data: BodyType<UpdateBookingChangeStatusRequest>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      const changeBookingStatus =  useChangeBookingStatusHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useChangeBookingStatusHook>>>, {id: number;data: BodyType<UpdateBookingChangeStatusRequest>}> = (props) => {
          const {id,data} = props ?? {};

          return  changeBookingStatus(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ChangeBookingStatusMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useChangeBookingStatusHook>>>>
    export type ChangeBookingStatusMutationBody = BodyType<UpdateBookingChangeStatusRequest>
    export type ChangeBookingStatusMutationError = ErrorType<ApiAppError>

    /**
 * @summary Change Booking Status
 */
export const useChangeBookingStatus = <TError = ErrorType<ApiAppError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useChangeBookingStatusHook>>>, TError,{id: number;data: BodyType<UpdateBookingChangeStatusRequest>}, TContext>, request?: SecondParameter<ReturnType<typeof useApi>>}
): UseMutationResult<
        Awaited<ReturnType<ReturnType<typeof useChangeBookingStatusHook>>>,
        TError,
        {id: number;data: BodyType<UpdateBookingChangeStatusRequest>},
        TContext
      > => {

      const mutationOptions = useChangeBookingStatusMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Update Booking Visit Information
 */
export const useUpdateBookingVisitInformationHook = () => {
        const updateBookingVisitInformation = useApi<UpdateBookingResponse>();

        return useCallback((
    id: number,
    updateBookingUpdateVisitInformationRequest: BodyType<UpdateBookingUpdateVisitInformationRequest>,
 options?: SecondParameter<ReturnType<typeof useApi>>,) => {
        return updateBookingVisitInformation(
          {url: `/booking/${id}/visit_information`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: updateBookingUpdateVisitInformationRequest
    },
          options);
        }, [updateBookingVisitInformation])
      }
    


export const useUpdateBookingVisitInformationMutationOptions = <TError = ErrorType<ApiAppError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateBookingVisitInformationHook>>>, TError,{id: number;data: BodyType<UpdateBookingUpdateVisitInformationRequest>}, TContext>, request?: SecondParameter<ReturnType<typeof useApi>>}
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateBookingVisitInformationHook>>>, TError,{id: number;data: BodyType<UpdateBookingUpdateVisitInformationRequest>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      const updateBookingVisitInformation =  useUpdateBookingVisitInformationHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useUpdateBookingVisitInformationHook>>>, {id: number;data: BodyType<UpdateBookingUpdateVisitInformationRequest>}> = (props) => {
          const {id,data} = props ?? {};

          return  updateBookingVisitInformation(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateBookingVisitInformationMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useUpdateBookingVisitInformationHook>>>>
    export type UpdateBookingVisitInformationMutationBody = BodyType<UpdateBookingUpdateVisitInformationRequest>
    export type UpdateBookingVisitInformationMutationError = ErrorType<ApiAppError>

    /**
 * @summary Update Booking Visit Information
 */
export const useUpdateBookingVisitInformation = <TError = ErrorType<ApiAppError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateBookingVisitInformationHook>>>, TError,{id: number;data: BodyType<UpdateBookingUpdateVisitInformationRequest>}, TContext>, request?: SecondParameter<ReturnType<typeof useApi>>}
): UseMutationResult<
        Awaited<ReturnType<ReturnType<typeof useUpdateBookingVisitInformationHook>>>,
        TError,
        {id: number;data: BodyType<UpdateBookingUpdateVisitInformationRequest>},
        TContext
      > => {

      const mutationOptions = useUpdateBookingVisitInformationMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Get Bookings
 */
export const useGetBookingsHook = () => {
        const getBookings = useApi<GetBookingsResponse>();

        return useCallback((
    params?: GetBookingsParams,
 options?: SecondParameter<ReturnType<typeof useApi>>,signal?: AbortSignal
) => {
        return getBookings(
          {url: `/bookings`, method: 'GET',
        params, signal
    },
          options);
        }, [getBookings])
      }
    

export const getGetBookingsQueryKey = (params?: GetBookingsParams,) => {
    return [`/bookings`, ...(params ? [params]: [])] as const;
    }

    
export const useGetBookingsQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetBookingsHook>>>, TError = ErrorType<ApiAppError>>(params?: GetBookingsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetBookingsHook>>>, TError, TData>>, request?: SecondParameter<ReturnType<typeof useApi>>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetBookingsQueryKey(params);

  const getBookings =  useGetBookingsHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetBookingsHook>>>> = ({ signal }) => getBookings(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetBookingsHook>>>, TError, TData> & { queryKey: QueryKey }
}

export type GetBookingsQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetBookingsHook>>>>
export type GetBookingsQueryError = ErrorType<ApiAppError>


export function useGetBookings<TData = Awaited<ReturnType<ReturnType<typeof useGetBookingsHook>>>, TError = ErrorType<ApiAppError>>(
 params: undefined |  GetBookingsParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetBookingsHook>>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<ReturnType<typeof useGetBookingsHook>>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<ReturnType<typeof useApi>>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetBookings<TData = Awaited<ReturnType<ReturnType<typeof useGetBookingsHook>>>, TError = ErrorType<ApiAppError>>(
 params?: GetBookingsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetBookingsHook>>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<ReturnType<typeof useGetBookingsHook>>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<ReturnType<typeof useApi>>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetBookings<TData = Awaited<ReturnType<ReturnType<typeof useGetBookingsHook>>>, TError = ErrorType<ApiAppError>>(
 params?: GetBookingsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetBookingsHook>>>, TError, TData>>, request?: SecondParameter<ReturnType<typeof useApi>>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Get Bookings
 */

export function useGetBookings<TData = Awaited<ReturnType<ReturnType<typeof useGetBookingsHook>>>, TError = ErrorType<ApiAppError>>(
 params?: GetBookingsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetBookingsHook>>>, TError, TData>>, request?: SecondParameter<ReturnType<typeof useApi>>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = useGetBookingsQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



