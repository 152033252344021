import { type I18n, type MessageDescriptor } from "@lingui/core";
import { msg } from "@lingui/macro";

import { GetBookingDataPaymentCategoryDrinks } from "@/shared/api/generated/schemas";

export const bookingPaymentFormDrinksLabels: Record<
  Exclude<GetBookingDataPaymentCategoryDrinks, null>,
  MessageDescriptor
> = {
  invoice: msg`Faktura`,
  cash: msg`Kontant felles`,
  cash_individual: msg`Kontant enkeltvis`,
} as const;

export const bookingPaymentFormDrinksOptions = (i18n: I18n) =>
  Object.values(GetBookingDataPaymentCategoryDrinks).map((value) => ({
    value,
    label: i18n.t(bookingPaymentFormDrinksLabels[value]),
  }));
