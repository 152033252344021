import { useUser } from "@clerk/clerk-react";
import { createRootRouteWithContext, Outlet } from "@tanstack/react-router";
import { TanStackRouterDevtools } from "@tanstack/router-devtools";

import ErrorBoundary from "@/shared/providers/error-boundary";

interface RouterContext {
  auth:
    | {
        isSignedIn: false;
        user: null;
      }
    | {
        isSignedIn: true;
        user: ReturnType<typeof useUser>["user"];
      };
}

export const Route = createRootRouteWithContext<RouterContext>()({
  component: () => (
    <ErrorBoundary>
      <Outlet />
      {import.meta.env.DEV && <TanStackRouterDevtools />}
    </ErrorBoundary>
  ),
});
