import { Trans } from "@lingui/macro";
import { Button, Modal } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconPlus } from "@tabler/icons-react";

import CreateBookingForm from "./create-booking-form";

export default function CreateBooking() {
  const [opened, { open, close }] = useDisclosure(false);

  return (
    <>
      <Button onClick={open} leftSection={<IconPlus />}>
        <Trans>Opprett booking</Trans>
      </Button>

      <Modal
        opened={opened}
        onClose={close}
        size="lg"
        title={<Trans>Opprett booking</Trans>}
      >
        <CreateBookingForm close={close} />
      </Modal>
    </>
  );
}
