import { createFileRoute, redirect } from "@tanstack/react-router";

export const Route = createFileRoute("/_auth-layout/")({
  beforeLoad: () => {
    redirect({
      to: "/bookings",
      throw: true,
    });
  },
});
