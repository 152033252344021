import { Divider, Flex, Group } from "@mantine/core";
import {
  MRT_GlobalFilterTextInput,
  MRT_RowData,
  MRT_ShowHideColumnsButton,
  MRT_TableInstance,
  MRT_TableOptions,
  MRT_TablePagination,
  MRT_ToggleFiltersButton,
  MRT_ToggleFullScreenButton,
} from "mantine-react-table";
import { MRT_Localization_NO } from "mantine-react-table/locales/no/index.esm.mjs";

export const getDefaultMRTOptions = <TData extends MRT_RowData>(): Partial<
  MRT_TableOptions<TData>
> => ({
  localization: MRT_Localization_NO,
  initialState: {
    showGlobalFilter: true,
  },
  renderTopToolbar: ({ table }) => (
    <>
      <Flex justify="space-between" p="md">
        <Group gap="xs">
          <MRT_GlobalFilterTextInput variant="default" mx={0} table={table} />
        </Group>

        <Group gap="xs">
          <MRT_ToggleFiltersButton table={table} />
          <MRT_ShowHideColumnsButton table={table} />
          <MRT_ToggleFullScreenButton table={table} />
        </Group>
      </Flex>
      <Divider />
    </>
  ),
  renderBottomToolbar: ({ table }) => (
    <>
      <Divider />
      <Flex justify="end" py={6} px={4}>
        <MRT_TablePagination table={table} />
      </Flex>
    </>
  ),
  positionActionsColumn: "last",
  displayColumnDefOptions: {
    "mrt-row-actions": {
      header: "",
      size: 60,
      mantineTableHeadCellProps: {
        align: "right",
      },
      mantineTableBodyCellProps: {
        align: "right",
      },
    },
  },
});

export const DefaultMRTTopToolbar = <TData extends MRT_RowData>({
  table,
  children,
}: {
  table: MRT_TableInstance<TData>;
  children?: React.ReactNode;
}) => (
  <>
    <Flex justify="space-between" p="md">
      <Group gap="xs">
        <MRT_GlobalFilterTextInput variant="default" mx={0} table={table} />
        {children}
      </Group>

      <Group gap="xs">
        <MRT_ToggleFiltersButton table={table} />
        <MRT_ShowHideColumnsButton table={table} />
        <MRT_ToggleFullScreenButton table={table} />
      </Group>
    </Flex>
    <Divider />
  </>
);
