import { useAuth } from "@clerk/clerk-react";
import Axios, { AxiosError, AxiosRequestConfig, isAxiosError } from "axios";
import qs from "qs";

import { env } from "@/env";

export const axiosInstance = Axios.create({
  baseURL: new URL("/api/v1", env.VITE_API_URL).toString(),
  paramsSerializer: (params) => {
    return qs.stringify(params, { arrayFormat: "comma" });
  },
});

export const useApi = <TResponse, TData = unknown>(): ((
  config: AxiosRequestConfig<TData>,
  /**
   * @param options - can be used to alter request settings calling useGetSomethingById hook
   * This is rather escape hatch, but could be usefull when OpenAPI schema is not precise enough
   * one usecase was to remove default "Content-Type" header and allow browser to set it when uploading files
   * @example useGetSomethingById({ id: 1 }, { request: { headers: { "Content-Type": undefined } } })
   */
  options?: AxiosRequestConfig<TData>,
) => Promise<TResponse>) => {
  const { getToken } = useAuth();

  return async (config, options) => {
    const token = await getToken();

    try {
      const promise = await axiosInstance<TResponse>({
        ...config,
        ...options,
        headers: {
          Authorization: token ? `Bearer ${token}` : undefined,
          ...config.headers,
          ...options?.headers,
        },
      });

      return promise.data;
    } catch (error) {
      // AxiosError is an error returned external API
      // rethrow it so that it will be handled by onError in ReactQueryProvider for useQuery calls
      // or custom onError defined when calling useMutation
      if (isAxiosError<TResponse, TData>(error)) {
        throw error;
      } else {
        // if it's not an AxiosError, it's an error thrown by the client
        // likely issue with useApi configuration, getting token or else
        throw error;
      }
    }
  };
};

export default useApi;

// In some case with react-query and swr you want to be able to override the return error type so you can also do it here like this
export type ErrorType<Error> = AxiosError<Error>;

// In case you want to wrap the body type (optional)
// (if the custom instance is processing data before sending it, like changing the case for example)
export type BodyType<BodyData> = BodyData;
