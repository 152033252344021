import { t, Trans } from "@lingui/macro";
import {
  Button,
  Group,
  Modal,
  Stack,
  Text,
  TextInput,
  Title,
} from "@mantine/core";
import { useForm, zodResolver } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { IconPlus } from "@tabler/icons-react";
import { useNavigate } from "@tanstack/react-router";
import { z } from "zod";

import { useCreateCustomer } from "@/shared/api/generated/customers";

const FormSchema = z.object({
  name: z.string().min(1, { message: t`Velg navn` }),
});

type FormInitial = z.input<typeof FormSchema>;
type FormSubmit = z.output<typeof FormSchema>;

export default function CreateCustomer() {
  const [opened, { open, close }] = useDisclosure(false);

  const form = useForm<FormInitial, (v: FormInitial) => FormSubmit>({
    mode: "uncontrolled",
    initialValues: {
      name: "",
    },
    validate: zodResolver(FormSchema),
  });

  const { mutateAsync: createCustomer } = useCreateCustomer();

  const navigate = useNavigate();

  async function handleSubmit(values: FormSubmit) {
    const { data } = await createCustomer({ data: { name: values.name } });
    await navigate({
      to: "/customers/$customerId",
      params: { customerId: data.id.toString() },
    });
  }

  return (
    <>
      <Button onClick={open} leftSection={<IconPlus />}>
        <Trans>Opprett kunde</Trans>
      </Button>

      <Modal
        opened={opened}
        onClose={close}
        size="lg"
        title={
          <Title size="sm">
            <Trans>Opprett kunde</Trans>
          </Title>
        }
        transitionProps={{
          onExited: () => form.reset(),
        }}
      >
        <Text size="sm" c="dimmed" mb={8}>
          <Trans>
            Fyll ut den informasjonen du har tilgjengelig. Alle felter kan
            endres i ettertid.
          </Trans>
        </Text>

        <form onSubmit={form.onSubmit(handleSubmit)}>
          <Stack>
            <TextInput
              label={t`Kundenavn`}
              placeholder={t`Kundenavn`}
              withAsterisk
              {...form.getInputProps("name")}
            />

            <Group justify="end" gap={8} mt={8}>
              <Button variant="subtle" onClick={close}>
                <Trans>Avbryt</Trans>
              </Button>
              <Button type="submit">
                <Trans>Opprett</Trans>
              </Button>
            </Group>
          </Stack>
        </form>
      </Modal>
    </>
  );
}
