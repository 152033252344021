/**
 * Generated by orval v7.1.1 🍺
 * Do not edit manually.
 * Template API
 * Something something. <br><br>The spec is available at <a href='/swagger/api.json'>/swagger/api.json</a>.
 * OpenAPI spec version: 0
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import {
  useCallback
} from 'react'
import type {
  ApiAppError,
  CreateEmployeeRequest,
  CreateEmployeeResponse,
  GetEmployeeResponse,
  GetEmployeesParams,
  GetEmployeesResponse,
  UpdateEmployeeRequest,
  UpdateEmployeeResponse
} from './schemas'
import { useApi } from '../client';
import type { ErrorType, BodyType } from '../client';



type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


/**
 * @summary Create Employee
 */
export const useCreateEmployeeHook = () => {
        const createEmployee = useApi<CreateEmployeeResponse>();

        return useCallback((
    createEmployeeRequest: BodyType<CreateEmployeeRequest>,
 options?: SecondParameter<ReturnType<typeof useApi>>,) => {
        return createEmployee(
          {url: `/employee`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: createEmployeeRequest
    },
          options);
        }, [createEmployee])
      }
    


export const useCreateEmployeeMutationOptions = <TError = ErrorType<ApiAppError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCreateEmployeeHook>>>, TError,{data: BodyType<CreateEmployeeRequest>}, TContext>, request?: SecondParameter<ReturnType<typeof useApi>>}
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCreateEmployeeHook>>>, TError,{data: BodyType<CreateEmployeeRequest>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      const createEmployee =  useCreateEmployeeHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useCreateEmployeeHook>>>, {data: BodyType<CreateEmployeeRequest>}> = (props) => {
          const {data} = props ?? {};

          return  createEmployee(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CreateEmployeeMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useCreateEmployeeHook>>>>
    export type CreateEmployeeMutationBody = BodyType<CreateEmployeeRequest>
    export type CreateEmployeeMutationError = ErrorType<ApiAppError>

    /**
 * @summary Create Employee
 */
export const useCreateEmployee = <TError = ErrorType<ApiAppError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCreateEmployeeHook>>>, TError,{data: BodyType<CreateEmployeeRequest>}, TContext>, request?: SecondParameter<ReturnType<typeof useApi>>}
): UseMutationResult<
        Awaited<ReturnType<ReturnType<typeof useCreateEmployeeHook>>>,
        TError,
        {data: BodyType<CreateEmployeeRequest>},
        TContext
      > => {

      const mutationOptions = useCreateEmployeeMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Delete Employee
 */
export const useDeleteEmployeeHook = () => {
        const deleteEmployee = useApi<void>();

        return useCallback((
    id: string,
 options?: SecondParameter<ReturnType<typeof useApi>>,) => {
        return deleteEmployee(
          {url: `/employee/${id}`, method: 'DELETE'
    },
          options);
        }, [deleteEmployee])
      }
    


export const useDeleteEmployeeMutationOptions = <TError = ErrorType<ApiAppError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteEmployeeHook>>>, TError,{id: string}, TContext>, request?: SecondParameter<ReturnType<typeof useApi>>}
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteEmployeeHook>>>, TError,{id: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      const deleteEmployee =  useDeleteEmployeeHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useDeleteEmployeeHook>>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  deleteEmployee(id,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteEmployeeMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useDeleteEmployeeHook>>>>
    
    export type DeleteEmployeeMutationError = ErrorType<ApiAppError>

    /**
 * @summary Delete Employee
 */
export const useDeleteEmployee = <TError = ErrorType<ApiAppError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteEmployeeHook>>>, TError,{id: string}, TContext>, request?: SecondParameter<ReturnType<typeof useApi>>}
): UseMutationResult<
        Awaited<ReturnType<ReturnType<typeof useDeleteEmployeeHook>>>,
        TError,
        {id: string},
        TContext
      > => {

      const mutationOptions = useDeleteEmployeeMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Get Employee
 */
export const useGetEmployeeHook = () => {
        const getEmployee = useApi<GetEmployeeResponse>();

        return useCallback((
    id: string,
 options?: SecondParameter<ReturnType<typeof useApi>>,signal?: AbortSignal
) => {
        return getEmployee(
          {url: `/employee/${id}`, method: 'GET', signal
    },
          options);
        }, [getEmployee])
      }
    

export const getGetEmployeeQueryKey = (id: string,) => {
    return [`/employee/${id}`] as const;
    }

    
export const useGetEmployeeQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetEmployeeHook>>>, TError = ErrorType<ApiAppError>>(id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetEmployeeHook>>>, TError, TData>>, request?: SecondParameter<ReturnType<typeof useApi>>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetEmployeeQueryKey(id);

  const getEmployee =  useGetEmployeeHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetEmployeeHook>>>> = ({ signal }) => getEmployee(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetEmployeeHook>>>, TError, TData> & { queryKey: QueryKey }
}

export type GetEmployeeQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetEmployeeHook>>>>
export type GetEmployeeQueryError = ErrorType<ApiAppError>


export function useGetEmployee<TData = Awaited<ReturnType<ReturnType<typeof useGetEmployeeHook>>>, TError = ErrorType<ApiAppError>>(
 id: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetEmployeeHook>>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<ReturnType<typeof useGetEmployeeHook>>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<ReturnType<typeof useApi>>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetEmployee<TData = Awaited<ReturnType<ReturnType<typeof useGetEmployeeHook>>>, TError = ErrorType<ApiAppError>>(
 id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetEmployeeHook>>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<ReturnType<typeof useGetEmployeeHook>>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<ReturnType<typeof useApi>>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetEmployee<TData = Awaited<ReturnType<ReturnType<typeof useGetEmployeeHook>>>, TError = ErrorType<ApiAppError>>(
 id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetEmployeeHook>>>, TError, TData>>, request?: SecondParameter<ReturnType<typeof useApi>>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Get Employee
 */

export function useGetEmployee<TData = Awaited<ReturnType<ReturnType<typeof useGetEmployeeHook>>>, TError = ErrorType<ApiAppError>>(
 id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetEmployeeHook>>>, TError, TData>>, request?: SecondParameter<ReturnType<typeof useApi>>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = useGetEmployeeQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Update Employee
 */
export const useUpdateEmployeeHook = () => {
        const updateEmployee = useApi<UpdateEmployeeResponse>();

        return useCallback((
    id: string,
    updateEmployeeRequest: BodyType<UpdateEmployeeRequest>,
 options?: SecondParameter<ReturnType<typeof useApi>>,) => {
        return updateEmployee(
          {url: `/employee/${id}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: updateEmployeeRequest
    },
          options);
        }, [updateEmployee])
      }
    


export const useUpdateEmployeeMutationOptions = <TError = ErrorType<ApiAppError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateEmployeeHook>>>, TError,{id: string;data: BodyType<UpdateEmployeeRequest>}, TContext>, request?: SecondParameter<ReturnType<typeof useApi>>}
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateEmployeeHook>>>, TError,{id: string;data: BodyType<UpdateEmployeeRequest>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      const updateEmployee =  useUpdateEmployeeHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useUpdateEmployeeHook>>>, {id: string;data: BodyType<UpdateEmployeeRequest>}> = (props) => {
          const {id,data} = props ?? {};

          return  updateEmployee(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateEmployeeMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useUpdateEmployeeHook>>>>
    export type UpdateEmployeeMutationBody = BodyType<UpdateEmployeeRequest>
    export type UpdateEmployeeMutationError = ErrorType<ApiAppError>

    /**
 * @summary Update Employee
 */
export const useUpdateEmployee = <TError = ErrorType<ApiAppError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateEmployeeHook>>>, TError,{id: string;data: BodyType<UpdateEmployeeRequest>}, TContext>, request?: SecondParameter<ReturnType<typeof useApi>>}
): UseMutationResult<
        Awaited<ReturnType<ReturnType<typeof useUpdateEmployeeHook>>>,
        TError,
        {id: string;data: BodyType<UpdateEmployeeRequest>},
        TContext
      > => {

      const mutationOptions = useUpdateEmployeeMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Get Employees
 */
export const useGetEmployeesHook = () => {
        const getEmployees = useApi<GetEmployeesResponse>();

        return useCallback((
    params?: GetEmployeesParams,
 options?: SecondParameter<ReturnType<typeof useApi>>,signal?: AbortSignal
) => {
        return getEmployees(
          {url: `/employees`, method: 'GET',
        params, signal
    },
          options);
        }, [getEmployees])
      }
    

export const getGetEmployeesQueryKey = (params?: GetEmployeesParams,) => {
    return [`/employees`, ...(params ? [params]: [])] as const;
    }

    
export const useGetEmployeesQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetEmployeesHook>>>, TError = ErrorType<ApiAppError>>(params?: GetEmployeesParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetEmployeesHook>>>, TError, TData>>, request?: SecondParameter<ReturnType<typeof useApi>>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetEmployeesQueryKey(params);

  const getEmployees =  useGetEmployeesHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetEmployeesHook>>>> = ({ signal }) => getEmployees(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetEmployeesHook>>>, TError, TData> & { queryKey: QueryKey }
}

export type GetEmployeesQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetEmployeesHook>>>>
export type GetEmployeesQueryError = ErrorType<ApiAppError>


export function useGetEmployees<TData = Awaited<ReturnType<ReturnType<typeof useGetEmployeesHook>>>, TError = ErrorType<ApiAppError>>(
 params: undefined |  GetEmployeesParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetEmployeesHook>>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<ReturnType<typeof useGetEmployeesHook>>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<ReturnType<typeof useApi>>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetEmployees<TData = Awaited<ReturnType<ReturnType<typeof useGetEmployeesHook>>>, TError = ErrorType<ApiAppError>>(
 params?: GetEmployeesParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetEmployeesHook>>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<ReturnType<typeof useGetEmployeesHook>>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<ReturnType<typeof useApi>>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetEmployees<TData = Awaited<ReturnType<ReturnType<typeof useGetEmployeesHook>>>, TError = ErrorType<ApiAppError>>(
 params?: GetEmployeesParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetEmployeesHook>>>, TError, TData>>, request?: SecondParameter<ReturnType<typeof useApi>>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Get Employees
 */

export function useGetEmployees<TData = Awaited<ReturnType<ReturnType<typeof useGetEmployeesHook>>>, TError = ErrorType<ApiAppError>>(
 params?: GetEmployeesParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetEmployeesHook>>>, TError, TData>>, request?: SecondParameter<ReturnType<typeof useApi>>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = useGetEmployeesQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



