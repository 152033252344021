import { useState } from "react";
import { UseFormReturnType } from "@mantine/form";

/**
 * Wraps `form.watch` to provide a hook-like API for watching form values.
 * Helps with ergonomics when using controlled forms is not performant enough.
 * NOTE: If you can use controlled forms, prefer using `form.getValues()` or `form.values` directly.
 */
export function useFormWatch<TValues, TFieldName extends keyof TValues>(
  form: UseFormReturnType<TValues>,
  fieldName: TFieldName,
) {
  const [value, setValue] = useState<TValues[TFieldName]>(
    form.values[fieldName],
  );

  form.watch(fieldName, ({ value }) => {
    // @ts-expect-error value is never unknown in this case
    setValue(value);
  });

  return value;
}
