import { t, Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import {
  Avatar,
  Box,
  Flex,
  Grid,
  Group,
  LoadingOverlay,
  MultiSelect,
  Paper,
  Skeleton,
  Stack,
  Text,
  TextInput,
} from "@mantine/core";
import { createFileRoute, useBlocker } from "@tanstack/react-router";

import useUpdateEmployeeForm from "@/features/employees/update-employee/use-update-employee-form";
import { useGetEmployee } from "@/shared/api/generated/employee";
import { useGetLocations } from "@/shared/api/generated/locations";

export const Route = createFileRoute("/_auth-layout/employees/$employeeId/")({
  component: EmployeeDetailsPage,
});

function EmployeeDetailsPage() {
  const { employeeId } = Route.useParams();

  const { i18n } = useLingui();

  const { data, isLoading } = useGetEmployee(employeeId);
  const { data: locations } = useGetLocations();
  const { form, handleSubmit } = useUpdateEmployeeForm(employeeId, data?.data);

  useBlocker({
    blockerFn: () =>
      window.confirm(
        t`Det er gjort endringer i skjemaet som ikke er lagret. Trykk "OK" for å forkaste endringene.`,
      ),
    condition: form.isDirty(),
  });

  return (
    <Box p="lg">
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Group justify="space-between">
          <h1>
            {isLoading ? (
              <Skeleton h={40} w={200} />
            ) : (
              <Trans>
                {data?.data.firstName} {data?.data.lastName ?? ""}
              </Trans>
            )}
          </h1>

          <Stack gap={0}>
            <Text size="sm" c="dimmed" style={{ textAlign: "right" }}>
              {data?.data.createdAt ? (
                <Trans>
                  Opprettet:{" "}
                  {i18n.date(data?.data.createdAt, {
                    dateStyle: "short",
                    timeStyle: "long",
                  })}
                </Trans>
              ) : null}
            </Text>
            <Text size="sm" c="dimmed">
              {data?.data.updatedAt ? (
                <Trans>
                  Sist endret:{" "}
                  {i18n.date(data?.data.updatedAt, {
                    dateStyle: "short",
                    timeStyle: "long",
                  })}
                </Trans>
              ) : null}
            </Text>
          </Stack>
        </Group>

        <Grid align="stretch" pos="relative">
          <LoadingOverlay visible={isLoading} />

          <Grid.Col span={4}>
            <Paper p="xs" h="100%">
              <Flex justify="center">
                <Avatar color="primary" size={125}>
                  {data?.data.firstName?.[0]}
                  {data?.data.lastName?.[0] ?? ""}
                </Avatar>
              </Flex>

              <Stack>
                <TextInput
                  label={t`Fornavn`}
                  placeholder={t`Fornavn`}
                  key={form.key("firstName")}
                  {...form.getInputProps("firstName")}
                  withAsterisk
                />
                <TextInput
                  label={t`Etternavn`}
                  placeholder={t`Etternavn`}
                  key={form.key("lastName")}
                  {...form.getInputProps("lastName")}
                />
              </Stack>
            </Paper>
          </Grid.Col>

          <Grid.Col span={6}>
            <Stack h="100%">
              <Paper p="xs">
                <Text size="sm" mb="sm">
                  <Trans>Kontaktinformasjon</Trans>
                </Text>

                <Group grow align="start">
                  <TextInput
                    label={t`E-post`}
                    placeholder={t`Velg e-post`}
                    key={form.key("email")}
                    {...form.getInputProps("email")}
                    withAsterisk
                  />
                  <TextInput
                    label={t`Telefon`}
                    placeholder={t`Velg telefon`}
                    key={form.key("phone")}
                    {...form.getInputProps("phone")}
                  />
                </Group>
              </Paper>
              <Paper p="xs" h="100%">
                <Text size="sm" mb="sm">
                  <Trans>Arbeidsinformasjon</Trans>
                </Text>

                <MultiSelect
                  label={t`Lokasjoner`}
                  placeholder={t`Velg lokasjon(er)`}
                  data={locations?.data?.map((l) => ({
                    value: l.id,
                    label: l.name,
                  }))}
                  key={form.key("locations")}
                  {...form.getInputProps("locations")}
                />
              </Paper>
            </Stack>
          </Grid.Col>
        </Grid>
      </form>
    </Box>
  );
}
