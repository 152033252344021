import { Trans } from "@lingui/macro";
import { Button, Modal, Title } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconPlus } from "@tabler/icons-react";

import NewCategoryForm from "./create-category-form";

export default function CreateCategory() {
  const [opened, { open, close }] = useDisclosure(false);

  return (
    <>
      <Button onClick={open} leftSection={<IconPlus />}>
        <Trans>Opprett kategori</Trans>
      </Button>

      <Modal
        opened={opened}
        onClose={close}
        size="lg"
        title={
          <Title size="sm">
            <Trans>Opprett kategori</Trans>
          </Title>
        }
      >
        <NewCategoryForm close={close} />
      </Modal>
    </>
  );
}
