import { ClerkProvider } from "@clerk/clerk-react";
import { nbNO } from "@clerk/localizations";

import { env } from "@/env";

export default function ClerkProviderWrapper({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <ClerkProvider
      publishableKey={env.VITE_CLERK_PUBLISHABLE_KEY}
      afterSignOutUrl="/"
      localization={nbNO}
    >
      {children}
    </ClerkProvider>
  );
}
