import { StrictMode } from "react";
import ReactDOM from "react-dom/client";

import { initializeSentry } from "@/shared/utils/sentry/init";

import App from "./App";

initializeSentry();

const rootElement = document.getElementById("root")!;

if (!rootElement.innerHTML) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <StrictMode>
      <App />
    </StrictMode>,
  );
}
