import type { Messages } from "@lingui/core";
import { i18n } from "@lingui/core";
import dayjs from "dayjs";

export const locales = {
  en: "English",
  nb: "Norwegian Bokmål",
};

// make sure locale is supported by dajys as well https://github.com/iamkun/dayjs/tree/dev/src/locale
export const defaultLocale: keyof typeof locales = "nb";

export const localeCookieName = "app_locale";

export async function dynamicActivate(locale: string) {
  const { messages } = (await import(
    `../../../shared/translations/${locale}/messages.ts`
  )) as {
    messages: Messages;
  };
  i18n.load(locale, messages);
  i18n.activate(locale);
  dayjs.locale(locale);
}
