/**
 * Generated by orval v7.1.1 🍺
 * Do not edit manually.
 * Template API
 * Something something. <br><br>The spec is available at <a href='/swagger/api.json'>/swagger/api.json</a>.
 * OpenAPI spec version: 0
 */

export type GetBookingDataVisitCategory = typeof GetBookingDataVisitCategory[keyof typeof GetBookingDataVisitCategory];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetBookingDataVisitCategory = {
  event: 'event',
  online: 'online',
  company: 'company',
  school: 'school',
  kindergarten: 'kindergarten',
  compschool: 'compschool',
  cruise: 'cruise',
  hurtigruten: 'hurtigruten',
  havila: 'havila',
  tour: 'tour',
  local: 'local',
  freeentry: 'freeentry',
  conference: 'conference',
  hikinggroup: 'hikinggroup',
} as const;
