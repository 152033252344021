import * as Sentry from "@sentry/react";

import { router } from "@/router";

export function initializeSentry() {
  if (import.meta.env.PROD) {
    Sentry.init({
      dsn: "https://0cafb84a25eb77fa5f5e0439ca99e865@o4506745663979520.ingest.us.sentry.io/4508002172076032",
      integrations: [
        Sentry.tanstackRouterBrowserTracingIntegration(router),
        Sentry.replayIntegration({
          maskAllText: false,
          networkDetailAllowUrls: [window.location.origin],
        }),
      ],
      // Tracing
      tracesSampleRate: 1.0, //  Capture 100% of the transactions
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
      // Session Replay
      replaysSessionSampleRate: 0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
  }
}
