import { useMemo } from "react";
import { t, Trans } from "@lingui/macro";
import { ActionIcon, Box, Menu, Text, Title } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { openConfirmModal } from "@mantine/modals";
import { IconDots } from "@tabler/icons-react";
import { useQueryClient } from "@tanstack/react-query";
import {
  createMRTColumnHelper,
  MantineReactTable,
  MRT_ColumnDef,
  useMantineReactTable,
} from "mantine-react-table";

import {
  getGetCategoriesQueryKey,
  useDeleteCategory,
  useGetCategories,
} from "@/shared/api/generated/categories";
import { GetCategoriesItem } from "@/shared/api/generated/schemas";
import { getDefaultMRTOptions } from "@/shared/components/table/defaultMRTOptions";

import UpdateCategoryModal from "./update-product-category/update-category-modal";

export default function CategoriesTable() {
  const { data, isLoading } = useGetCategories();

  const ch = createMRTColumnHelper<GetCategoriesItem>();

  const columns = [
    ch.accessor("name", { header: t`Navn` }),
    ch.display({
      id: "actions",
      header: "",
      Cell: CategoryActions,
      enableColumnActions: false,
      size: 70,
    }),
  ];

  const table = useMantineReactTable({
    columns,
    data: useMemo(() => data?.data ?? [], [data?.data]),
    state: {
      isLoading,
    },
    ...getDefaultMRTOptions<GetCategoriesItem>(),
  });

  return <MantineReactTable table={table} />;
}

const CategoryActions: MRT_ColumnDef<GetCategoriesItem>["Cell"] = ({ row }) => {
  const [isUpdateModalOpen, updateModal] = useDisclosure(false);

  return (
    <Box style={{ textAlign: "right" }}>
      <Menu position="bottom-end">
        <Menu.Target>
          <ActionIcon variant="subtle" color="gray">
            <IconDots />
          </ActionIcon>
        </Menu.Target>

        <Menu.Dropdown>
          <Menu.Item onClick={() => updateModal.open()}>
            <Trans>Endre</Trans>
          </Menu.Item>
          <DeleteMenuItem id={row.original.id} name={row.original.name} />
        </Menu.Dropdown>
      </Menu>

      <UpdateCategoryModal
        category={row.original}
        opened={isUpdateModalOpen}
        close={updateModal.close}
      />
    </Box>
  );
};

function DeleteMenuItem({ id, name }: { id: string; name: string }) {
  const queryClient = useQueryClient();

  const { mutateAsync: deleteCategory } = useDeleteCategory({
    mutation: {
      onSuccess: () =>
        queryClient.invalidateQueries({ queryKey: getGetCategoriesQueryKey() }),
    },
  });

  const openModal = () =>
    openConfirmModal({
      title: (
        <Title component="span" size="sm">
          <Trans>Slett kategori</Trans>
        </Title>
      ),
      children: (
        <Text size="sm">
          <Trans>
            Er du sikker på at du vil fjerne kategorien <b>{name}</b>?
          </Trans>
        </Text>
      ),
      labels: { confirm: t`Slett`, cancel: t`Avbryt` },
      cancelProps: { variant: "subtle" },
      confirmProps: { color: "red" },
      onConfirm: () => deleteCategory({ id }),
    });

  return (
    <Menu.Item color="red" onClick={() => openModal()}>
      <Trans>Slett</Trans>
    </Menu.Item>
  );
}
