/**
 * Generated by orval v7.1.1 🍺
 * Do not edit manually.
 * Template API
 * Something something. <br><br>The spec is available at <a href='/swagger/api.json'>/swagger/api.json</a>.
 * OpenAPI spec version: 0
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import {
  useCallback
} from 'react'
import type {
  ApiAppError,
  CreateProductRequest,
  CreateProductResponse,
  GetProductResponse,
  GetProductsParams,
  GetProductsResponse,
  UpdateProductRequest,
  UpdateProductResponse
} from './schemas'
import { useApi } from '../client';
import type { ErrorType, BodyType } from '../client';



type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


/**
 * @summary Create Product
 */
export const useCreateProductHook = () => {
        const createProduct = useApi<CreateProductResponse>();

        return useCallback((
    createProductRequest: BodyType<CreateProductRequest>,
 options?: SecondParameter<ReturnType<typeof useApi>>,) => {
        return createProduct(
          {url: `/product`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: createProductRequest
    },
          options);
        }, [createProduct])
      }
    


export const useCreateProductMutationOptions = <TError = ErrorType<ApiAppError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCreateProductHook>>>, TError,{data: BodyType<CreateProductRequest>}, TContext>, request?: SecondParameter<ReturnType<typeof useApi>>}
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCreateProductHook>>>, TError,{data: BodyType<CreateProductRequest>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      const createProduct =  useCreateProductHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useCreateProductHook>>>, {data: BodyType<CreateProductRequest>}> = (props) => {
          const {data} = props ?? {};

          return  createProduct(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CreateProductMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useCreateProductHook>>>>
    export type CreateProductMutationBody = BodyType<CreateProductRequest>
    export type CreateProductMutationError = ErrorType<ApiAppError>

    /**
 * @summary Create Product
 */
export const useCreateProduct = <TError = ErrorType<ApiAppError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCreateProductHook>>>, TError,{data: BodyType<CreateProductRequest>}, TContext>, request?: SecondParameter<ReturnType<typeof useApi>>}
): UseMutationResult<
        Awaited<ReturnType<ReturnType<typeof useCreateProductHook>>>,
        TError,
        {data: BodyType<CreateProductRequest>},
        TContext
      > => {

      const mutationOptions = useCreateProductMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Delete Product
 */
export const useDeleteProductHook = () => {
        const deleteProduct = useApi<void>();

        return useCallback((
    id: string,
 options?: SecondParameter<ReturnType<typeof useApi>>,) => {
        return deleteProduct(
          {url: `/product/${id}`, method: 'DELETE'
    },
          options);
        }, [deleteProduct])
      }
    


export const useDeleteProductMutationOptions = <TError = ErrorType<ApiAppError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteProductHook>>>, TError,{id: string}, TContext>, request?: SecondParameter<ReturnType<typeof useApi>>}
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteProductHook>>>, TError,{id: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      const deleteProduct =  useDeleteProductHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useDeleteProductHook>>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  deleteProduct(id,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteProductMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useDeleteProductHook>>>>
    
    export type DeleteProductMutationError = ErrorType<ApiAppError>

    /**
 * @summary Delete Product
 */
export const useDeleteProduct = <TError = ErrorType<ApiAppError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteProductHook>>>, TError,{id: string}, TContext>, request?: SecondParameter<ReturnType<typeof useApi>>}
): UseMutationResult<
        Awaited<ReturnType<ReturnType<typeof useDeleteProductHook>>>,
        TError,
        {id: string},
        TContext
      > => {

      const mutationOptions = useDeleteProductMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Get Product
 */
export const useGetProductHook = () => {
        const getProduct = useApi<GetProductResponse>();

        return useCallback((
    id: string,
 options?: SecondParameter<ReturnType<typeof useApi>>,signal?: AbortSignal
) => {
        return getProduct(
          {url: `/product/${id}`, method: 'GET', signal
    },
          options);
        }, [getProduct])
      }
    

export const getGetProductQueryKey = (id: string,) => {
    return [`/product/${id}`] as const;
    }

    
export const useGetProductQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetProductHook>>>, TError = ErrorType<ApiAppError>>(id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetProductHook>>>, TError, TData>>, request?: SecondParameter<ReturnType<typeof useApi>>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetProductQueryKey(id);

  const getProduct =  useGetProductHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetProductHook>>>> = ({ signal }) => getProduct(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetProductHook>>>, TError, TData> & { queryKey: QueryKey }
}

export type GetProductQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetProductHook>>>>
export type GetProductQueryError = ErrorType<ApiAppError>


export function useGetProduct<TData = Awaited<ReturnType<ReturnType<typeof useGetProductHook>>>, TError = ErrorType<ApiAppError>>(
 id: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetProductHook>>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<ReturnType<typeof useGetProductHook>>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<ReturnType<typeof useApi>>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetProduct<TData = Awaited<ReturnType<ReturnType<typeof useGetProductHook>>>, TError = ErrorType<ApiAppError>>(
 id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetProductHook>>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<ReturnType<typeof useGetProductHook>>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<ReturnType<typeof useApi>>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetProduct<TData = Awaited<ReturnType<ReturnType<typeof useGetProductHook>>>, TError = ErrorType<ApiAppError>>(
 id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetProductHook>>>, TError, TData>>, request?: SecondParameter<ReturnType<typeof useApi>>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Get Product
 */

export function useGetProduct<TData = Awaited<ReturnType<ReturnType<typeof useGetProductHook>>>, TError = ErrorType<ApiAppError>>(
 id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetProductHook>>>, TError, TData>>, request?: SecondParameter<ReturnType<typeof useApi>>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = useGetProductQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Update Product
 */
export const useUpdateProductHook = () => {
        const updateProduct = useApi<UpdateProductResponse>();

        return useCallback((
    id: string,
    updateProductRequest: BodyType<UpdateProductRequest>,
 options?: SecondParameter<ReturnType<typeof useApi>>,) => {
        return updateProduct(
          {url: `/product/${id}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: updateProductRequest
    },
          options);
        }, [updateProduct])
      }
    


export const useUpdateProductMutationOptions = <TError = ErrorType<ApiAppError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateProductHook>>>, TError,{id: string;data: BodyType<UpdateProductRequest>}, TContext>, request?: SecondParameter<ReturnType<typeof useApi>>}
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateProductHook>>>, TError,{id: string;data: BodyType<UpdateProductRequest>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      const updateProduct =  useUpdateProductHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useUpdateProductHook>>>, {id: string;data: BodyType<UpdateProductRequest>}> = (props) => {
          const {id,data} = props ?? {};

          return  updateProduct(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateProductMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useUpdateProductHook>>>>
    export type UpdateProductMutationBody = BodyType<UpdateProductRequest>
    export type UpdateProductMutationError = ErrorType<ApiAppError>

    /**
 * @summary Update Product
 */
export const useUpdateProduct = <TError = ErrorType<ApiAppError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateProductHook>>>, TError,{id: string;data: BodyType<UpdateProductRequest>}, TContext>, request?: SecondParameter<ReturnType<typeof useApi>>}
): UseMutationResult<
        Awaited<ReturnType<ReturnType<typeof useUpdateProductHook>>>,
        TError,
        {id: string;data: BodyType<UpdateProductRequest>},
        TContext
      > => {

      const mutationOptions = useUpdateProductMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Get Products
 */
export const useGetProductsHook = () => {
        const getProducts = useApi<GetProductsResponse>();

        return useCallback((
    params?: GetProductsParams,
 options?: SecondParameter<ReturnType<typeof useApi>>,signal?: AbortSignal
) => {
        return getProducts(
          {url: `/products`, method: 'GET',
        params, signal
    },
          options);
        }, [getProducts])
      }
    

export const getGetProductsQueryKey = (params?: GetProductsParams,) => {
    return [`/products`, ...(params ? [params]: [])] as const;
    }

    
export const useGetProductsQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetProductsHook>>>, TError = ErrorType<ApiAppError>>(params?: GetProductsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetProductsHook>>>, TError, TData>>, request?: SecondParameter<ReturnType<typeof useApi>>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetProductsQueryKey(params);

  const getProducts =  useGetProductsHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetProductsHook>>>> = ({ signal }) => getProducts(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetProductsHook>>>, TError, TData> & { queryKey: QueryKey }
}

export type GetProductsQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetProductsHook>>>>
export type GetProductsQueryError = ErrorType<ApiAppError>


export function useGetProducts<TData = Awaited<ReturnType<ReturnType<typeof useGetProductsHook>>>, TError = ErrorType<ApiAppError>>(
 params: undefined |  GetProductsParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetProductsHook>>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<ReturnType<typeof useGetProductsHook>>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<ReturnType<typeof useApi>>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetProducts<TData = Awaited<ReturnType<ReturnType<typeof useGetProductsHook>>>, TError = ErrorType<ApiAppError>>(
 params?: GetProductsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetProductsHook>>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<ReturnType<typeof useGetProductsHook>>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<ReturnType<typeof useApi>>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetProducts<TData = Awaited<ReturnType<ReturnType<typeof useGetProductsHook>>>, TError = ErrorType<ApiAppError>>(
 params?: GetProductsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetProductsHook>>>, TError, TData>>, request?: SecondParameter<ReturnType<typeof useApi>>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Get Products
 */

export function useGetProducts<TData = Awaited<ReturnType<ReturnType<typeof useGetProductsHook>>>, TError = ErrorType<ApiAppError>>(
 params?: GetProductsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetProductsHook>>>, TError, TData>>, request?: SecondParameter<ReturnType<typeof useApi>>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = useGetProductsQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



