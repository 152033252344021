import { useLingui } from "@lingui/react";
import { Select } from "@mantine/core";
import { useQueryClient } from "@tanstack/react-query";

import {
  getGetBookingQueryKey,
  getGetBookingsQueryKey,
  useChangeBookingStatus,
} from "@/shared/api/generated/booking";
import {
  GetBookingResponse,
  GetBookingsItem,
  GetBookingsItemStatus,
  GetBookingsResponse,
} from "@/shared/api/generated/schemas";

import { bookingStatusOptions } from "../utils/status";

export default function StatusSelector({
  booking,
}: {
  booking: GetBookingsItem;
}) {
  const { i18n } = useLingui();

  const queryClient = useQueryClient();

  const { mutate: changeBookingStatus } = useChangeBookingStatus({
    mutation: {
      onSuccess: (data) => {
        queryClient.setQueryData<GetBookingResponse>(
          getGetBookingQueryKey(booking.id),
          data,
        );
        // Update this booking in the for GET /bookings
        queryClient.setQueriesData<GetBookingsResponse>(
          { queryKey: getGetBookingsQueryKey() },
          (old) => ({
            ...old,
            data: (old?.data ?? []).map((booking) =>
              booking.id === data.data.id ? data.data : booking,
            ),
          }),
        );
      },
    },
  });

  return (
    <Select
      value={booking.status}
      data={bookingStatusOptions(i18n)}
      onChange={(value) => {
        const newStatus = value as GetBookingsItemStatus | null;

        if (newStatus) {
          changeBookingStatus({
            id: booking.id,
            data: {
              status: newStatus,
              updatedAt: booking.updatedAt,
            },
          });
        }
      }}
    />
  );
}
