// List of all countries with names and ISO 3166-1 codes
// https://github.com/umpirsky/country-list/blob/master/data/nb_NO/country.json
// JSON transformed into Select-consumable [{ val{ value: "ue", label: "XX", lab{ value: "el", label: "Country Name" } }]
export const countryListnbNO = [
  { value: "AF", label: "Afghanistan" },
  { value: "AL", label: "Albania" },
  { value: "DZ", label: "Algerie" },
  { value: "AS", label: "Amerikansk Samoa" },
  { value: "AD", label: "Andorra" },
  { value: "AO", label: "Angola" },
  { value: "AI", label: "Anguilla" },
  { value: "AQ", label: "Antarktis" },
  { value: "AG", label: "Antigua og Barbuda" },
  { value: "AR", label: "Argentina" },
  { value: "AM", label: "Armenia" },
  { value: "AW", label: "Aruba" },
  { value: "AZ", label: "Aserbajdsjan" },
  { value: "AU", label: "Australia" },
  { value: "BS", label: "Bahamas" },
  { value: "BH", label: "Bahrain" },
  { value: "BD", label: "Bangladesh" },
  { value: "BB", label: "Barbados" },
  { value: "BE", label: "Belgia" },
  { value: "BZ", label: "Belize" },
  { value: "BJ", label: "Benin" },
  { value: "BM", label: "Bermuda" },
  { value: "BT", label: "Bhutan" },
  { value: "BO", label: "Bolivia" },
  { value: "BA", label: "Bosnia-Hercegovina" },
  { value: "BW", label: "Botswana" },
  { value: "BV", label: "Bouvet\u00f8ya" },
  { value: "BR", label: "Brasil" },
  { value: "BN", label: "Brunei" },
  { value: "BG", label: "Bulgaria" },
  { value: "BF", label: "Burkina Faso" },
  { value: "BI", label: "Burundi" },
  { value: "CA", label: "Canada" },
  { value: "KY", label: "Cayman\u00f8yene" },
  { value: "CL", label: "Chile" },
  { value: "CX", label: "Christmas\u00f8ya" },
  { value: "CO", label: "Colombia" },
  { value: "CK", label: "Cook\u00f8yene" },
  { value: "CR", label: "Costa Rica" },
  { value: "CU", label: "Cuba" },
  { value: "CW", label: "Cura\u00e7ao" },
  { value: "DK", label: "Danmark" },
  { value: "VI", label: "De amerikanske jomfru\u00f8yene" },
  { value: "VG", label: "De britiske jomfru\u00f8yene" },
  { value: "AE", label: "De forente arabiske emirater" },
  { value: "TF", label: "De franske s\u00f8rterritorier" },
  { value: "DO", label: "Den dominikanske republikk" },
  { value: "CF", label: "Den sentralafrikanske republikk" },
  { value: "IO", label: "Det britiske territoriet i Indiahavet" },
  { value: "PS", label: "Det palestinske omr\u00e5det" },
  { value: "DJ", label: "Djibouti" },
  { value: "DM", label: "Dominica" },
  { value: "EC", label: "Ecuador" },
  { value: "EG", label: "Egypt" },
  { value: "GQ", label: "Ekvatorial-Guinea" },
  { value: "SV", label: "El Salvador" },
  { value: "CI", label: "Elfenbenskysten" },
  { value: "ER", label: "Eritrea" },
  { value: "EE", label: "Estland" },
  { value: "SZ", label: "Eswatini" },
  { value: "ET", label: "Etiopia" },
  { value: "FK", label: "Falklands\u00f8yene" },
  { value: "FJ", label: "Fiji" },
  { value: "PH", label: "Filippinene" },
  { value: "FI", label: "Finland" },
  { value: "FR", label: "Frankrike" },
  { value: "GF", label: "Fransk Guyana" },
  { value: "PF", label: "Fransk Polynesia" },
  { value: "FO", label: "F\u00e6r\u00f8yene" },
  { value: "GA", label: "Gabon" },
  { value: "GM", label: "Gambia" },
  { value: "GE", label: "Georgia" },
  { value: "GH", label: "Ghana" },
  { value: "GI", label: "Gibraltar" },
  { value: "GD", label: "Grenada" },
  { value: "GL", label: "Gr\u00f8nland" },
  { value: "GP", label: "Guadeloupe" },
  { value: "GU", label: "Guam" },
  { value: "GT", label: "Guatemala" },
  { value: "GG", label: "Guernsey" },
  { value: "GN", label: "Guinea" },
  { value: "GW", label: "Guinea-Bissau" },
  { value: "GY", label: "Guyana" },
  { value: "HT", label: "Haiti" },
  { value: "HM", label: "Heard- og McDonald\u00f8yene" },
  { value: "GR", label: "Hellas" },
  { value: "HN", label: "Honduras" },
  { value: "HK", label: "Hongkong S.A.R. Kina" },
  { value: "BY", label: "Hviterussland" },
  { value: "IN", label: "India" },
  { value: "ID", label: "Indonesia" },
  { value: "IQ", label: "Irak" },
  { value: "IR", label: "Iran" },
  { value: "IE", label: "Irland" },
  { value: "IS", label: "Island" },
  { value: "IL", label: "Israel" },
  { value: "IT", label: "Italia" },
  { value: "JM", label: "Jamaica" },
  { value: "JP", label: "Japan" },
  { value: "YE", label: "Jemen" },
  { value: "JE", label: "Jersey" },
  { value: "JO", label: "Jordan" },
  { value: "KH", label: "Kambodsja" },
  { value: "CM", label: "Kamerun" },
  { value: "CV", label: "Kapp Verde" },
  { value: "BQ", label: "Karibisk Nederland" },
  { value: "KZ", label: "Kasakhstan" },
  { value: "KE", label: "Kenya" },
  { value: "CN", label: "Kina" },
  { value: "KG", label: "Kirgisistan" },
  { value: "KI", label: "Kiribati" },
  { value: "CC", label: "Kokos\u00f8yene" },
  { value: "KM", label: "Komorene" },
  { value: "CG", label: "Kongo-Brazzaville" },
  { value: "CD", label: "Kongo-Kinshasa" },
  { value: "HR", label: "Kroatia" },
  { value: "KW", label: "Kuwait" },
  { value: "CY", label: "Kypros" },
  { value: "LA", label: "Laos" },
  { value: "LV", label: "Latvia" },
  { value: "LS", label: "Lesotho" },
  { value: "LB", label: "Libanon" },
  { value: "LR", label: "Liberia" },
  { value: "LY", label: "Libya" },
  { value: "LI", label: "Liechtenstein" },
  { value: "LT", label: "Litauen" },
  { value: "LU", label: "Luxemburg" },
  { value: "MO", label: "Macao S.A.R. Kina" },
  { value: "MG", label: "Madagaskar" },
  { value: "MW", label: "Malawi" },
  { value: "MY", label: "Malaysia" },
  { value: "MV", label: "Maldivene" },
  { value: "ML", label: "Mali" },
  { value: "MT", label: "Malta" },
  { value: "IM", label: "Man" },
  { value: "MA", label: "Marokko" },
  { value: "MH", label: "Marshall\u00f8yene" },
  { value: "MQ", label: "Martinique" },
  { value: "MR", label: "Mauritania" },
  { value: "MU", label: "Mauritius" },
  { value: "YT", label: "Mayotte" },
  { value: "MX", label: "Mexico" },
  { value: "FM", label: "Mikronesiaf\u00f8derasjonen" },
  { value: "MD", label: "Moldova" },
  { value: "MC", label: "Monaco" },
  { value: "MN", label: "Mongolia" },
  { value: "ME", label: "Montenegro" },
  { value: "MS", label: "Montserrat" },
  { value: "MZ", label: "Mosambik" },
  { value: "MM", label: "Myanmar (Burma)" },
  { value: "NA", label: "Namibia" },
  { value: "NR", label: "Nauru" },
  { value: "NL", label: "Nederland" },
  { value: "NP", label: "Nepal" },
  { value: "NZ", label: "New Zealand" },
  { value: "NI", label: "Nicaragua" },
  { value: "NE", label: "Niger" },
  { value: "NG", label: "Nigeria" },
  { value: "NU", label: "Niue" },
  { value: "KP", label: "Nord-Korea" },
  { value: "MK", label: "Nord-Makedonia" },
  { value: "MP", label: "Nord-Marianene" },
  { value: "NF", label: "Norfolk\u00f8ya" },
  { value: "NO", label: "Norge" },
  { value: "NC", label: "Ny-Caledonia" },
  { value: "OM", label: "Oman" },
  { value: "PK", label: "Pakistan" },
  { value: "PW", label: "Palau" },
  { value: "PA", label: "Panama" },
  { value: "PG", label: "Papua Ny-Guinea" },
  { value: "PY", label: "Paraguay" },
  { value: "PE", label: "Peru" },
  { value: "PN", label: "Pitcairn\u00f8yene" },
  { value: "PL", label: "Polen" },
  { value: "PT", label: "Portugal" },
  { value: "PR", label: "Puerto Rico" },
  { value: "QA", label: "Qatar" },
  { value: "RE", label: "R\u00e9union" },
  { value: "RO", label: "Romania" },
  { value: "RU", label: "Russland" },
  { value: "RW", label: "Rwanda" },
  { value: "KN", label: "Saint Kitts og Nevis" },
  { value: "BL", label: "Saint-Barth\u00e9lemy" },
  { value: "MF", label: "Saint-Martin" },
  { value: "PM", label: "Saint-Pierre-et-Miquelon" },
  { value: "SB", label: "Salomon\u00f8yene" },
  { value: "WS", label: "Samoa" },
  { value: "SM", label: "San Marino" },
  { value: "ST", label: "S\u00e3o Tom\u00e9 og Pr\u00edncipe" },
  { value: "SA", label: "Saudi-Arabia" },
  { value: "SN", label: "Senegal" },
  { value: "RS", label: "Serbia" },
  { value: "SC", label: "Seychellene" },
  { value: "SL", label: "Sierra Leone" },
  { value: "SG", label: "Singapore" },
  { value: "SX", label: "Sint Maarten" },
  { value: "SK", label: "Slovakia" },
  { value: "SI", label: "Slovenia" },
  { value: "SO", label: "Somalia" },
  { value: "ES", label: "Spania" },
  { value: "LK", label: "Sri Lanka" },
  { value: "SH", label: "St. Helena" },
  { value: "LC", label: "St. Lucia" },
  { value: "VC", label: "St. Vincent og Grenadinene" },
  { value: "GB", label: "Storbritannia" },
  { value: "SD", label: "Sudan" },
  { value: "SR", label: "Surinam" },
  { value: "SJ", label: "Svalbard og Jan Mayen" },
  { value: "CH", label: "Sveits" },
  { value: "SE", label: "Sverige" },
  { value: "SY", label: "Syria" },
  { value: "ZA", label: "S\u00f8r-Afrika" },
  { value: "GS", label: "S\u00f8r-Georgia og S\u00f8r-Sandwich\u00f8yene" },
  { value: "KR", label: "S\u00f8r-Korea" },
  { value: "SS", label: "S\u00f8r-Sudan" },
  { value: "TJ", label: "Tadsjikistan" },
  { value: "TW", label: "Taiwan" },
  { value: "TZ", label: "Tanzania" },
  { value: "TH", label: "Thailand" },
  { value: "TG", label: "Togo" },
  { value: "TK", label: "Tokelau" },
  { value: "TO", label: "Tonga" },
  { value: "TT", label: "Trinidad og Tobago" },
  { value: "TD", label: "Tsjad" },
  { value: "CZ", label: "Tsjekkia" },
  { value: "TN", label: "Tunisia" },
  { value: "TM", label: "Turkmenistan" },
  { value: "TC", label: "Turks- og Caicos\u00f8yene" },
  { value: "TV", label: "Tuvalu" },
  { value: "TR", label: "Tyrkia" },
  { value: "DE", label: "Tyskland" },
  { value: "UG", label: "Uganda" },
  { value: "UA", label: "Ukraina" },
  { value: "HU", label: "Ungarn" },
  { value: "UY", label: "Uruguay" },
  { value: "US", label: "USA" },
  { value: "UM", label: "USAs ytre \u00f8yer" },
  { value: "UZ", label: "Usbekistan" },
  { value: "VU", label: "Vanuatu" },
  { value: "VA", label: "Vatikanstaten" },
  { value: "VE", label: "Venezuela" },
  { value: "EH", label: "Vest-Sahara" },
  { value: "VN", label: "Vietnam" },
  { value: "WF", label: "Wallis og Futuna" },
  { value: "ZM", label: "Zambia" },
  { value: "ZW", label: "Zimbabwe" },
  { value: "TL", label: "\u00d8st-Timor" },
  { value: "AT", label: "\u00d8sterrike" },
  { value: "AX", label: "\u00c5land" },
];
