import { t, Trans } from "@lingui/macro";
import { Button, Group, Stack, TextInput } from "@mantine/core";
import { useForm, zodResolver } from "@mantine/form";
import { useQueryClient } from "@tanstack/react-query";
import { z } from "zod";

import {
  getGetLocationsQueryKey,
  useUpdateLocation,
} from "@/shared/api/generated/locations";
import { GetLocationsItem } from "@/shared/api/generated/schemas";

const updateLocationSchema = z.object({
  name: z.string().min(1),
});

type UpdateLocationFormValues = z.infer<typeof updateLocationSchema>;

export default function UpdateLocationForm({
  location,
  close,
}: {
  location: GetLocationsItem;
  close: () => void;
}) {
  const queryClient = useQueryClient();

  const { mutateAsync: updateLocation } = useUpdateLocation({
    mutation: {
      onSuccess: () =>
        queryClient.invalidateQueries({ queryKey: getGetLocationsQueryKey() }),
    },
  });

  const form = useForm<UpdateLocationFormValues>({
    mode: "controlled",
    validate: zodResolver(updateLocationSchema),
    initialValues: {
      name: location.name,
    },
  });

  async function handleSubmit(values: UpdateLocationFormValues) {
    await updateLocation(
      {
        id: location.id,
        data: {
          name: values.name,
        },
      },
      {
        onSuccess: close,
      },
    );
  }

  return (
    <form onSubmit={form.onSubmit(handleSubmit)}>
      <Stack>
        <TextInput
          label={t`Navn`}
          placeholder={t`Velg navn`}
          withAsterisk
          {...form.getInputProps("name")}
        />

        <Group justify="end" gap={8} mt={8}>
          <Button variant="subtle" onClick={close}>
            <Trans>Avbryt</Trans>
          </Button>
          <Button type="submit">
            <Trans>Opprett</Trans>
          </Button>
        </Group>
      </Stack>
    </form>
  );
}
