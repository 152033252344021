import { useUser } from "@clerk/clerk-react";
import { RouterProvider } from "@tanstack/react-router";

import Providers from "@/shared/providers";
import { useSetSentryUser } from "@/shared/utils/sentry/setSentryUser";

import { router } from "./router";

function InnerApp() {
  const user = useUser();

  useSetSentryUser();

  if (!user.isLoaded) {
    return null;
  }

  return (
    <RouterProvider
      router={router}
      context={{
        auth: user,
      }}
    />
  );
}

export default function App() {
  return (
    <Providers>
      <InnerApp />
    </Providers>
  );
}
