import "@mantine/core/styles.css";
import "@mantine/dates/styles.css";
import "@mantine/notifications/styles.css";
import "mantine-react-table/styles.css";
import "./mantine-overrides.css";

import {
  createTheme,
  DEFAULT_THEME,
  DefaultMantineColor,
  Input,
  MantineColorsTuple,
  MantineProvider,
  Paper,
} from "@mantine/core";
import { ModalsProvider } from "@mantine/modals";
import { Notifications } from "@mantine/notifications";

import "dayjs/locale/nb";

import { DateInput } from "@mantine/dates";

import DatesProvider from "./mantine-dates-provider";

const theme = createTheme({
  fontFamily: `Ubuntu, ${DEFAULT_THEME.fontFamily}`,
  headings: {
    fontFamily: `Ubuntu, ${DEFAULT_THEME.fontFamily}`,
  },
  colors: {
    primary: [
      "#F0F3F9",
      "#DFE2ED",
      "#BBC2DB",
      "#94A1CA",
      "#7484BC",
      "#5F72B4",
      "#5469B2",
      "#46599C",
      "#3D4F8D",
      "#31447D",
    ],
  },
  primaryColor: "primary",
  components: {
    InputWrapper: Input.Wrapper.extend({
      defaultProps: {
        inputWrapperOrder: ["label", "input", "description", "error"],
      },
    }),
    Paper: Paper.extend({
      defaultProps: {
        shadow: "xs",
        withBorder: true,
      },
    }),
    DateInput: DateInput.extend({
      defaultProps: {
        valueFormat: "L",
      },
    }),
  },
});

export default function MantineProviderWrapper({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <MantineProvider theme={theme}>
      <DatesProvider>
        <ModalsProvider>
          <Notifications />
          {children}
        </ModalsProvider>
      </DatesProvider>
    </MantineProvider>
  );
}

type ExtendedCustomColors = "primary" | DefaultMantineColor;

declare module "@mantine/core" {
  export interface MantineThemeColorsOverride {
    colors: Record<ExtendedCustomColors, MantineColorsTuple>;
  }
}
