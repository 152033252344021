import { closestCenter, DndContext, DragEndEvent } from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import {
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { t, Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import {
  ActionIcon,
  Button,
  CSSProperties,
  Flex,
  Group,
  NumberInput,
  Paper,
  rem,
  ScrollArea,
  Select,
  Stack,
  Table,
  Text,
  TextInput,
} from "@mantine/core";
import { TimeInput } from "@mantine/dates";
import { useForm, zodResolver } from "@mantine/form";
import { randomId, useDebouncedCallback } from "@mantine/hooks";
import { IconGripVertical, IconMinus, IconPlus } from "@tabler/icons-react";
import { useQueryClient } from "@tanstack/react-query";
import { useBlocker } from "@tanstack/react-router";
import dayjs from "dayjs";
import { sortBy } from "lodash-es";
import { z } from "zod";

import {
  getGetBookingQueryKey,
  getGetBookingsQueryKey,
  useUpdateBookingProgram,
} from "@/shared/api/generated/booking";
import { useGetEmployees } from "@/shared/api/generated/employee";
import { useGetProducts } from "@/shared/api/generated/products";
import {
  GetBookingData,
  GetBookingResponse,
  GetBookingsResponse,
} from "@/shared/api/generated/schemas";

const programFormSchema = z.object({
  guideId: z.string().nullable(),
  program: z.array(
    z
      .object({
        id: z.string(),
        productId: z.string().nullable(), //.min(1, { message: t`Velg et produkt` }),
        start: z.string(),
        end: z.string(),
        price: z.number({ invalid_type_error: t`Pris må være et tall` }).int(),
        quantity: z
          .number({ invalid_type_error: t`Antall må være et tall` })
          .int(),
        comment: z.string().optional(),
      })
      .refine(
        (data) => {
          const start = dayjs(data.start, "HH:mm");
          const end = dayjs(data.end, "HH:mm");

          if (start.isValid() && end.isValid()) {
            return end.isAfter(start);
          }
          return true;
        },
        {
          message: t`Slutt må være etter start`,
          path: ["end"],
        },
      ),
  ),
});

type ProgramFormInitial = z.input<typeof programFormSchema>;
type ProgramFormSubmit = z.output<typeof programFormSchema>;

export default function Program({ booking }: { booking: GetBookingData }) {
  const { i18n } = useLingui();

  const debouncedSubmit = useDebouncedCallback(() => {
    form.onSubmit(handleSubmit)();
  }, 1000);

  const form = useForm<ProgramFormInitial, () => ProgramFormSubmit>({
    mode: "controlled",
    validate: zodResolver(programFormSchema),
    initialValues: {
      guideId: booking.guide?.id ?? null,
      program: sortBy(booking.program, "index")?.map((programItem) => ({
        id: programItem.id,
        productId: programItem.productID,
        start: programItem.start
          ? dayjs(programItem.start).format("HH:mm")
          : "",
        end: programItem.end ? dayjs(programItem.end).format("HH:mm") : "",
        comment: programItem.comment ?? "",
        price: programItem.price,
        quantity: programItem.quantity,
      })) ?? [
        {
          id: randomId(),
          productId: null,
          start: "",
          end: "",
          comment: "",
          price: 0,
          quantity: 0,
        },
      ],
    },
    onValuesChange: debouncedSubmit,
    clearInputErrorOnChange: false,
  });

  useBlocker({
    blockerFn: () =>
      window.confirm(
        t`Det er gjort endringer i skjemaet som ikke er lagret. Trykk "OK" for å forkaste endringene.`,
      ),
    condition: form.isDirty(),
  });

  const queryClient = useQueryClient();

  const { mutateAsync: updateProgram } = useUpdateBookingProgram({
    mutation: {
      onSuccess: (data) => {
        // Update entire booking itself
        queryClient.setQueryData<GetBookingResponse>(
          getGetBookingQueryKey(data.data.id),
          data,
        );
        // Update this booking in the for GET /bookings
        queryClient.setQueriesData<GetBookingsResponse>(
          { queryKey: getGetBookingsQueryKey() },
          (old) => ({
            ...old,
            data: (old?.data ?? []).map((booking) =>
              booking.id === data.data.id ? data.data : booking,
            ),
          }),
        );
      },
    },
  });

  async function handleSubmit(values: ProgramFormSubmit) {
    await updateProgram({
      id: booking.id,
      data: {
        guideId: values.guideId,
        products: values.program.map((programItem, index) => {
          let start: string | null = null;

          if (programItem.start) {
            const startTime = dayjs(programItem.start, "HH:mm");
            start = dayjs(booking.arrival)
              .hour(startTime.hour())
              .minute(startTime.minute())
              .toISOString();
          }

          let end: string | null = null;

          if (programItem.end) {
            const endTime = dayjs(programItem.end, "HH:mm");
            end = dayjs(booking.arrival)
              .hour(endTime.hour())
              .minute(endTime.minute())
              .toISOString();
          }

          return {
            id: programItem.id.startsWith("mantine")
              ? undefined
              : programItem.id,
            productId: programItem.productId,
            start: start,
            end: end,
            comment: programItem.comment,
            price: programItem.price,
            quantity: programItem.quantity,
            index,
          };
        }),
        updatedAt: booking.updatedAt,
      },
    });
    form.resetDirty(values);
  }

  const program = form.getValues().program;

  const totalValue = program.reduce((acc, programItem) => {
    return acc + Number(programItem.price) * programItem.quantity;
  }, 0);

  function handleDragEnd({ active, over }: DragEndEvent) {
    if (active && over && active.id !== over.id) {
      form.reorderListItem("program", {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
        from: active.data.current?.sortable.index,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        to:
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          over.data.current?.sortable.index ??
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          active.data.current?.sortable.index,
      });
    }
  }

  function addNewProgramItem() {
    form.insertListItem("program", {
      id: randomId(),
      productId: null,
      start: "",
      end: "",
      comment: "",
      price: 0,
      quantity: 0,
    } satisfies ProgramFormInitial["program"][0]);
  }

  const { data: employees } = useGetEmployees(
    {
      locations: [booking.location.id],
    },
    {
      query: { enabled: !!booking.location.id },
    },
  );

  const guideOptions = employees?.data?.map((employee) => ({
    value: employee.id,
    label: `${employee.firstName} ${employee.lastName ?? ""}`,
  }));

  return (
    <DndContext
      collisionDetection={closestCenter}
      modifiers={[restrictToVerticalAxis]}
      onDragEnd={handleDragEnd}
    >
      <Paper p="xs">
        <Text size="sm" mb="sm">
          <Trans>Program</Trans>
        </Text>
        <Stack gap="sm">
          <Group>
            <Select
              label={t`Guide`}
              placeholder={t`Velg guide`}
              data={guideOptions}
              {...form.getInputProps("guideId")}
              key={form.key("guideId")}
            />
          </Group>
          <ScrollArea>
            <Table
              withRowBorders={false}
              horizontalSpacing={4}
              verticalSpacing={4}
            >
              <Table.Thead>
                <Table.Tr>
                  <Table.Th style={{ width: rem(32) }} />
                  <Table.Th style={{ minWidth: rem(150) }}>
                    <Trans>Produkt</Trans>
                  </Table.Th>
                  <Table.Th style={{ width: rem(75) }}>
                    <Trans>Start</Trans>
                  </Table.Th>
                  <Table.Th style={{ width: rem(75) }}>
                    <Trans>Slutt</Trans>
                  </Table.Th>
                  <Table.Th style={{ minWidth: rem(150) }}>
                    <Trans>Kommentar</Trans>
                  </Table.Th>
                  <Table.Th style={{ width: rem(100), minWidth: rem(70) }}>
                    <Trans>Pris</Trans>
                  </Table.Th>
                  <Table.Th style={{ width: rem(90), minWidth: rem(80) }}>
                    <Trans>Antall</Trans>
                  </Table.Th>
                  <Table.Th style={{ width: rem(36) }} />
                </Table.Tr>
              </Table.Thead>

              <Table.Tbody>
                <SortableContext
                  items={program.map(({ id }) => id)}
                  strategy={verticalListSortingStrategy}
                >
                  {program.map((programItem, index) => (
                    <DraggableRow
                      key={programItem.id}
                      id={programItem.id}
                      locationId={booking.location.id}
                      index={index}
                      form={form}
                    />
                  ))}
                  {program.length === 0 && (
                    <Table.Tr>
                      <Table.Td colSpan={8}>
                        <Text c="dimmed" size="sm" ta="center" mt="sm">
                          <Trans>
                            Klikk på &quot;Legg til produkt&quot; for å legge
                            til produkter i programmet
                          </Trans>
                        </Text>
                      </Table.Td>
                    </Table.Tr>
                  )}
                </SortableContext>
              </Table.Tbody>
            </Table>
          </ScrollArea>

          <Group>
            <Button
              onClick={addNewProgramItem}
              leftSection={<IconPlus />}
              size="xs"
              variant="outline"
            >
              <Trans>Legg til produkt</Trans>
            </Button>
          </Group>

          <Flex justify="end" gap={8}>
            <Text fw="bold">
              <Trans>Total</Trans>
            </Text>
            <Text fw="bold">
              {i18n.number(totalValue, {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              })}
            </Text>
            <Text c="dimmed">NOK</Text>
          </Flex>
        </Stack>
      </Paper>
    </DndContext>
  );
}

function DraggableRow({
  id,
  index,
  locationId,
  form,
}: {
  id: string;
  index: number;
  locationId: string;
  form: ReturnType<typeof useForm<ProgramFormInitial>>;
}) {
  const { transform, transition, setNodeRef, attributes, listeners } =
    useSortable({
      id,
    });

  const style: CSSProperties = {
    transform: CSS.Transform.toString(transform),
    transition: transition,
  };

  const { data: existingProducts } = useGetProducts(
    { locations: [locationId] },
    { query: { enabled: !!locationId } },
  );

  /**
   * Sets the end time of the product based on the start time and the duration of the product.
   * @param startTimeInput expected format: "HH:mm"
   */
  function setProductEndTime(startTimeInput: string) {
    const startTime = dayjs(startTimeInput, "HH:mm");

    if (startTime.isValid()) {
      const selectedProductId = form.getValues().program[index].productId;

      if (selectedProductId) {
        const product = existingProducts?.data?.find(
          (product) => product.id === selectedProductId,
        );

        if (product?.duration) {
          const endTime = startTime.add(product.duration, "minutes");

          form.setFieldValue(`program.${index}.end`, endTime.format("HH:mm"));
        }
      }
    }
  }

  const onStartTimeChange =
    (index: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
      // onChange is typed as any
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      form.getInputProps(`program.${index}.start`).onChange(e);
      setProductEndTime(e.target.value);
    };

  const productOptions = existingProducts?.data?.map((product) => ({
    value: product.id,
    label: product.nameNO,
  }));

  function setProductPrice(selectedProductId: string | null) {
    if (selectedProductId) {
      const product = existingProducts?.data?.find(
        (product) => product.id === selectedProductId,
      );

      // Set prica automatically if product has price
      if (product?.price) {
        form.setFieldValue(`program.${index}.price`, product.price);
      }

      // Set amount to 1 if it's 0
      if (form.getValues().program[index].quantity === 0) {
        form.setFieldValue(`program.${index}.quantity`, 1);
      }
    } else {
      // Set price to 0 if product is removed
      form.setFieldValue(`program.${index}.price`, 0);
    }
  }

  function maybeResetProductStartEnd(selectedProductId: string | null) {
    if (selectedProductId) {
      const product = existingProducts?.data?.find(
        (product) => product.id === selectedProductId,
      );

      // if selected product has no duration, reset start and end
      if (!product?.duration) {
        form.setFieldValue(`program.${index}.start`, "");
        form.setFieldValue(`program.${index}.end`, "");
      }
    }
  }

  const onProductChange =
    (index: number) => (selectedProductId: string | null) => {
      // onChange is typed as any
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      form
        .getInputProps(`program.${index}.productId`)
        .onChange(selectedProductId);
      setProductPrice(selectedProductId);
      maybeResetProductStartEnd(selectedProductId);
    };

  const selectedProduct = existingProducts?.data?.find(
    (product) => product.id === form.getValues().program[index].productId,
  );

  const hasDuration = selectedProduct?.duration !== null;

  return (
    <Table.Tr ref={setNodeRef} style={style}>
      <Table.Td {...attributes} {...listeners} valign="top">
        <IconGripVertical style={{ marginTop: 6 }} />
      </Table.Td>
      <Table.Td valign="top">
        <Select
          data={productOptions}
          clearable
          {...form.getInputProps(`program.${index}.productId`)}
          onChange={onProductChange(index)}
          key={form.key(`program.${index}.productId`)}
        />
      </Table.Td>
      <Table.Td valign="top">
        <TimeInput
          {...form.getInputProps(`program.${index}.start`)}
          key={form.key(`program.${index}.start`)}
          onChange={onStartTimeChange(index)}
          disabled={!hasDuration}
        />
      </Table.Td>
      <Table.Td valign="top">
        <TimeInput
          {...form.getInputProps(`program.${index}.end`)}
          key={form.key(`program.${index}.end`)}
          disabled={!hasDuration}
        />
      </Table.Td>
      <Table.Td valign="top">
        <TextInput
          {...form.getInputProps(`program.${index}.comment`)}
          key={form.key(`program.${index}.comment`)}
        />
      </Table.Td>
      <Table.Td valign="top">
        <NumberInput
          {...form.getInputProps(`program.${index}.price`)}
          key={form.key(`program.${index}.price`)}
          hideControls
          allowDecimal={false}
          allowNegative={false}
          thousandSeparator={" "}
          styles={{
            input: { textAlign: "right" },
          }}
        />
      </Table.Td>
      <Table.Td valign="top">
        <NumberInput
          {...form.getInputProps(`program.${index}.quantity`)}
          key={form.key(`program.${index}.quantity`)}
        />
      </Table.Td>
      <Table.Td valign="top">
        <ActionIcon
          onClick={() => {
            form.removeListItem("program", index);
          }}
          variant="outline"
          color="red"
          mt={4}
        >
          <IconMinus />
        </ActionIcon>
      </Table.Td>
    </Table.Tr>
  );
}
