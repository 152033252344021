import "@/shared/styles/global.css";

import ClerkProviderWrapper from "./clerk-provider";
import I18nProvider from "./i18n-provider";
import MantineProviderWrapper from "./mantine-provider";
import ReactQueryProvider from "./react-query-provider";

export default function Providers({ children }: { children: React.ReactNode }) {
  return (
    <I18nProvider>
      <MantineProviderWrapper>
        <ClerkProviderWrapper>
          <ReactQueryProvider>{children}</ReactQueryProvider>
        </ClerkProviderWrapper>
      </MantineProviderWrapper>
    </I18nProvider>
  );
}
