import { useEffect } from "react";
import { i18n } from "@lingui/core";
import { detect, fromCookie } from "@lingui/detect-locale";
import { I18nProvider as LinguiI18nProvider } from "@lingui/react";

import { defaultLocale, dynamicActivate, localeCookieName } from "./utils";

function I18nProvider({ children }: { children: React.ReactNode }) {
  useEffect(() => {
    void dynamicActivate(detect(fromCookie(localeCookieName)) ?? defaultLocale);
  }, []);

  return <LinguiI18nProvider i18n={i18n}>{children}</LinguiI18nProvider>;
}

export default I18nProvider;
