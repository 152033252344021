import { type I18n, type MessageDescriptor } from "@lingui/core";
import { msg } from "@lingui/macro";

import { GetBookingDataStatus } from "@/shared/api/generated/schemas";

export const bookingStatusLabels: Record<
  Exclude<GetBookingDataStatus, null>,
  MessageDescriptor
> = {
  request: msg`Forespørsel`,
  reserved: msg`Reservert`,
  cancelled: msg`Avlyst`,
  confirmed: msg`Bekreftet`,
  arrived: msg`Ankommet`,
  confirmationsent: msg`Bekreftelsesmelding sendt`,
} as const;

export const bookingStatusOptions = (i18n: I18n) =>
  Object.values(GetBookingDataStatus).map((value) => ({
    value,
    label: i18n.t(bookingStatusLabels[value]),
  }));
