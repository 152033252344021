/**
 * Generated by orval v7.1.1 🍺
 * Do not edit manually.
 * Template API
 * Something something. <br><br>The spec is available at <a href='/swagger/api.json'>/swagger/api.json</a>.
 * OpenAPI spec version: 0
 */

/**
 * @nullable
 */
export type CreateBookingRequestPaymentCategoryDrinks = typeof CreateBookingRequestPaymentCategoryDrinks[keyof typeof CreateBookingRequestPaymentCategoryDrinks] | null;


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CreateBookingRequestPaymentCategoryDrinks = {
  invoice: 'invoice',
  cash: 'cash',
  cash_individual: 'cash_individual',
} as const;
