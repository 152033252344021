// List of all languages with names and ISO 639-1 codes
// https://github.com/umpirsky/language-list/blob/master/data/nb_NO/language.json
// JSON transformed into Select-consumable [{ value: "xx", label: "language name" }]
export const langugeListnbNO = [
  { value: "ab", label: "abkhasisk" },
  { value: "ace", label: "achinesisk" },
  { value: "ach", label: "acoli" },
  { value: "ada", label: "adangme" },
  { value: "ady", label: "adyghe" },
  { value: "aa", label: "afar" },
  { value: "afh", label: "afrihili" },
  { value: "af", label: "afrikaans" },
  { value: "agq", label: "aghem" },
  { value: "ain", label: "ainu" },
  { value: "ak", label: "akan" },
  { value: "akk", label: "akkadisk" },
  { value: "bss", label: "akose" },
  { value: "akz", label: "Alabama" },
  { value: "sq", label: "albansk" },
  { value: "ale", label: "aleutisk" },
  { value: "arq", label: "Algerian Arabic" },
  { value: "ase", label: "American Sign Language" },
  { value: "en_US", label: "amerikansk engelsk" },
  { value: "am", label: "amharisk" },
  { value: "anp", label: "angika" },
  { value: "njo", label: "Ao Naga" },
  { value: "ar", label: "arabisk" },
  { value: "an", label: "aragonsk" },
  { value: "arc", label: "arameisk" },
  { value: "aro", label: "Araona" },
  { value: "arp", label: "arapaho" },
  { value: "arn", label: "araukansk" },
  { value: "arw", label: "arawak" },
  { value: "hy", label: "armensk" },
  { value: "rup", label: "aromansk" },
  { value: "frp", label: "Arpitan" },
  { value: "az", label: "aserbajdsjansk" },
  { value: "as", label: "assamisk" },
  { value: "ast", label: "asturisk" },
  { value: "asa", label: "asu" },
  { value: "cch", label: "atsam" },
  { value: "en_AU", label: "australsk engelsk" },
  { value: "av", label: "avarisk" },
  { value: "ae", label: "avestisk" },
  { value: "awa", label: "awadhi" },
  { value: "ay", label: "aymara" },
  { value: "bfq", label: "Badaga" },
  { value: "ksf", label: "bafia" },
  { value: "bfd", label: "bafut" },
  { value: "bqi", label: "Bakhtiari" },
  { value: "ban", label: "balinesisk" },
  { value: "bal", label: "baluchi" },
  { value: "bm", label: "bambara" },
  { value: "bax", label: "bamun" },
  { value: "bjn", label: "Banjar" },
  { value: "bas", label: "basa" },
  { value: "ba", label: "basjkirsk" },
  { value: "eu", label: "baskisk" },
  { value: "bbc", label: "Batak Toba" },
  { value: "bar", label: "Bavarian" },
  { value: "bej", label: "beja" },
  { value: "bem", label: "bemba" },
  { value: "bez", label: "bena" },
  { value: "bn", label: "bengali" },
  { value: "bew", label: "Betawi" },
  { value: "bho", label: "bhojpuri" },
  { value: "bik", label: "bikol" },
  { value: "bin", label: "bini" },
  { value: "bpy", label: "Bishnupriya" },
  { value: "bi", label: "bislama" },
  { value: "byn", label: "blin" },
  { value: "zbl", label: "blissymboler" },
  { value: "brx", label: "bodo" },
  { value: "bs", label: "bosnisk" },
  { value: "brh", label: "Brahui" },
  { value: "bra", label: "braj" },
  { value: "pt_BR", label: "brasiliansk portugisisk" },
  { value: "br", label: "bretonsk" },
  { value: "en_GB", label: "britisk engelsk" },
  { value: "bug", label: "buginesisk" },
  { value: "bg", label: "bulgarsk" },
  { value: "bum", label: "bulu" },
  { value: "bua", label: "buriat" },
  { value: "my", label: "burmesisk" },
  { value: "cad", label: "caddo" },
  { value: "frc", label: "Cajun French" },
  { value: "en_CA", label: "canadisk engelsk" },
  { value: "fr_CA", label: "canadisk fransk" },
  { value: "cps", label: "Capiznon" },
  { value: "cay", label: "cayuga" },
  { value: "ceb", label: "cebuansk" },
  { value: "dtp", label: "Central Dusun" },
  { value: "esu", label: "Central Yupik" },
  { value: "chg", label: "chagatai" },
  { value: "ch", label: "chamorro" },
  { value: "chr", label: "cherokesisk" },
  { value: "chy", label: "cheyenne" },
  { value: "chb", label: "chibcha" },
  { value: "qug", label: "Chimborazo Highland Quichua" },
  { value: "chn", label: "chinook" },
  { value: "chp", label: "chipewiansk" },
  { value: "cho", label: "choctaw" },
  { value: "chk", label: "chuukesisk" },
  { value: "cr", label: "cree" },
  { value: "mus", label: "creek" },
  { value: "dak", label: "dakota" },
  { value: "da", label: "dansk" },
  { value: "dar", label: "dargwa" },
  { value: "dzg", label: "dazaga" },
  { value: "del", label: "delaware" },
  { value: "din", label: "dinka" },
  { value: "dv", label: "divehi" },
  { value: "doi", label: "dogri" },
  { value: "dgr", label: "dogrib" },
  { value: "dua", label: "duala" },
  { value: "dyu", label: "dyula" },
  { value: "dz", label: "dzongkha" },
  { value: "efi", label: "efik" },
  { value: "arz", label: "Egyptian Arabic" },
  { value: "eka", label: "ekajuk" },
  { value: "elx", label: "elamittisk" },
  { value: "egl", label: "Emilian" },
  { value: "smn", label: "enaresamisk" },
  { value: "en", label: "engelsk" },
  { value: "myv", label: "erzya" },
  { value: "eo", label: "esperanto" },
  { value: "et", label: "estisk" },
  { value: "pt_PT", label: "europeisk portugisisk" },
  { value: "es_ES", label: "europeisk spansk" },
  { value: "ee", label: "ewe" },
  { value: "ewo", label: "ewondo" },
  { value: "ext", label: "Extremaduran" },
  { value: "fan", label: "fang" },
  { value: "fat", label: "fanti" },
  { value: "hif", label: "Fiji Hindi" },
  { value: "fj", label: "fijiansk" },
  { value: "fil", label: "filippinsk" },
  { value: "fi", label: "finsk" },
  { value: "nl_BE", label: "flamsk" },
  { value: "mul", label: "flere spr\u00e5k" },
  { value: "fon", label: "fon" },
  { value: "zh_Hans", label: "forenklet kinesisk" },
  { value: "gur", label: "Frafra" },
  { value: "fr", label: "fransk" },
  { value: "fur", label: "friuliansk" },
  { value: "ff", label: "fulani" },
  { value: "fo", label: "f\u00e6r\u00f8ysk" },
  { value: "phn", label: "f\u00f8nikisk" },
  { value: "gaa", label: "ga" },
  { value: "gag", label: "gagausisk" },
  { value: "gl", label: "galisisk" },
  { value: "egy", label: "gammelegyptisk" },
  { value: "ang", label: "gammelengelsk" },
  { value: "fro", label: "gammelfransk" },
  { value: "grc", label: "gammelgresk" },
  { value: "goh", label: "gammelh\u00f8ytysk" },
  { value: "sga", label: "gammelirsk" },
  { value: "non", label: "gammelnorsk" },
  { value: "peo", label: "gammelpersisk" },
  { value: "pro", label: "gammelproven\u00e7alsk" },
  { value: "gan", label: "Gan Chinese" },
  { value: "lg", label: "ganda" },
  { value: "gay", label: "gayo" },
  { value: "gba", label: "gbaya" },
  { value: "ka", label: "georgisk" },
  { value: "gez", label: "ges" },
  { value: "aln", label: "Gheg Albanian" },
  { value: "bbj", label: "ghomala" },
  { value: "glk", label: "Gilaki" },
  { value: "gom", label: "Goan Konkani" },
  { value: "gon", label: "gondi" },
  { value: "gor", label: "gorontalo" },
  { value: "got", label: "gotisk" },
  { value: "grb", label: "grebo" },
  { value: "el", label: "gresk" },
  { value: "kl", label: "gr\u00f8nlandsk" },
  { value: "gn", label: "guarani" },
  { value: "gu", label: "gujarati" },
  { value: "guz", label: "gusii" },
  { value: "gwi", label: "gwichin" },
  { value: "hai", label: "haida" },
  { value: "ht", label: "haitisk" },
  { value: "hak", label: "Hakka Chinese" },
  { value: "ha", label: "hausa" },
  { value: "haw", label: "hawaiisk" },
  { value: "he", label: "hebraisk" },
  { value: "hz", label: "herero" },
  { value: "hit", label: "hettittisk" },
  { value: "hil", label: "hiligaynon" },
  { value: "hi", label: "hindi" },
  { value: "ho", label: "hiri motu" },
  { value: "hmn", label: "hmong" },
  { value: "hup", label: "hupa" },
  { value: "be", label: "hviterussisk" },
  { value: "hsb", label: "h\u00f8ysorbisk" },
  { value: "iba", label: "iban" },
  { value: "ibb", label: "ibibio" },
  { value: "ig", label: "ibo" },
  { value: "io", label: "ido" },
  { value: "ilo", label: "iloko" },
  { value: "id", label: "indonesisk" },
  { value: "izh", label: "Ingrian" },
  { value: "inh", label: "ingusjisk" },
  { value: "ia", label: "interlingua" },
  { value: "ie", label: "interlingue" },
  { value: "iu", label: "inuktitut" },
  { value: "ik", label: "inupiak" },
  { value: "ga", label: "irsk" },
  { value: "is", label: "islandsk" },
  { value: "it", label: "italiensk" },
  { value: "sah", label: "jakutsk" },
  { value: "jam", label: "Jamaican Creole English" },
  { value: "ja", label: "japansk" },
  { value: "jv", label: "javanesisk" },
  { value: "yi", label: "jiddisk" },
  { value: "kaj", label: "jju" },
  { value: "dyo", label: "jola-fonyi" },
  { value: "yo", label: "joruba" },
  { value: "jut", label: "Jutish" },
  { value: "jrb", label: "j\u00f8dearabisk" },
  { value: "jpr", label: "j\u00f8depersisk" },
  { value: "kbd", label: "kabardisk" },
  { value: "kab", label: "kabylsk" },
  { value: "kac", label: "kachin" },
  { value: "kgp", label: "Kaingang" },
  { value: "kkj", label: "kako" },
  { value: "kln", label: "kalenjin" },
  { value: "xal", label: "kalmyk" },
  { value: "kam", label: "kamba" },
  { value: "kbl", label: "kanembu" },
  { value: "kn", label: "kannada" },
  { value: "yue", label: "kantonesisk" },
  { value: "kr", label: "kanuri" },
  { value: "kea", label: "kappverdisk" },
  { value: "krc", label: "karachay-balkar" },
  { value: "kaa", label: "karakalpakisk" },
  { value: "krl", label: "karelsk" },
  { value: "car", label: "karibisk" },
  { value: "kk", label: "kasakhisk" },
  { value: "ks", label: "kasjmiri" },
  { value: "csb", label: "kasjubisk" },
  { value: "ca", label: "katalansk" },
  { value: "kaw", label: "kawi" },
  { value: "ken", label: "Kenyang" },
  { value: "kha", label: "khasi" },
  { value: "km", label: "khmer" },
  { value: "kho", label: "khotanesisk" },
  { value: "khw", label: "Khowar" },
  { value: "ebu", label: "kiembu" },
  { value: "cgg", label: "kiga" },
  { value: "kg", label: "kikongo" },
  { value: "ki", label: "kikuyu" },
  { value: "kmb", label: "kimbundu" },
  { value: "krj", label: "Kinaray-a" },
  { value: "zh", label: "kinesisk" },
  { value: "rw", label: "kinyarwanda" },
  { value: "ky", label: "kirgisisk" },
  { value: "gil", label: "kiribatisk" },
  { value: "cu", label: "kirkeslavisk" },
  { value: "kiu", label: "Kirmanjki" },
  { value: "nwc", label: "klassisk newari" },
  { value: "syc", label: "klassisk syrisk" },
  { value: "tlh", label: "klingon" },
  { value: "bkm", label: "kom" },
  { value: "kv", label: "komi" },
  { value: "koi", label: "komipermjakisk" },
  { value: "swb", label: "komorisk" },
  { value: "swc", label: "kongolesisk swahili" },
  { value: "kok", label: "konkani" },
  { value: "cop", label: "koptisk" },
  { value: "ko", label: "koreansk" },
  { value: "kw", label: "kornisk" },
  { value: "kfo", label: "koro" },
  { value: "co", label: "korsikansk" },
  { value: "kos", label: "kosraeansk" },
  { value: "avk", label: "Kotava" },
  { value: "khq", label: "koyra chiini" },
  { value: "ses", label: "koyraboro senni" },
  { value: "kpe", label: "kpelle" },
  { value: "crh", label: "krimtatarisk" },
  { value: "kri", label: "Krio" },
  { value: "hr", label: "kroatisk" },
  { value: "kj", label: "kuanyama" },
  { value: "kum", label: "kumyk" },
  { value: "ku", label: "kurdisk" },
  { value: "ckb", label: "kurdisk (sorani)" },
  { value: "kru", label: "kurukh" },
  { value: "kut", label: "kutenai" },
  { value: "nmg", label: "kwasio" },
  { value: "ksh", label: "k\u00f8lnsk" },
  { value: "lad", label: "ladinsk" },
  { value: "lah", label: "lahnda" },
  { value: "lkt", label: "lakota" },
  { value: "lam", label: "lamba" },
  { value: "lag", label: "langi" },
  { value: "lo", label: "laotisk" },
  { value: "ltg", label: "Latgalian" },
  { value: "la", label: "latin" },
  { value: "es_419", label: "latinamerikansk spansk" },
  { value: "lv", label: "latvisk" },
  { value: "dsb", label: "lavsorbisk" },
  { value: "nds", label: "lavtysk" },
  { value: "lzz", label: "Laz" },
  { value: "lez", label: "lezghian" },
  { value: "lij", label: "Ligurian" },
  { value: "li", label: "limburgisk" },
  { value: "ln", label: "lingala" },
  { value: "lfn", label: "Lingua Franca Nova" },
  { value: "lt", label: "litauisk" },
  { value: "lzh", label: "Literary Chinese" },
  { value: "liv", label: "Livonian" },
  { value: "jbo", label: "lojban" },
  { value: "lmo", label: "Lombard" },
  { value: "sli", label: "Lower Silesian" },
  { value: "loz", label: "lozi" },
  { value: "lu", label: "luba-katanga" },
  { value: "lua", label: "luba-lulua" },
  { value: "luy", label: "luhya" },
  { value: "lui", label: "luiseno" },
  { value: "smj", label: "lulesamisk" },
  { value: "lun", label: "lunda" },
  { value: "luo", label: "luo" },
  { value: "lus", label: "lushai" },
  { value: "lb", label: "luxemburgsk" },
  { value: "mde", label: "maba" },
  { value: "jmc", label: "machame" },
  { value: "mg", label: "madagassisk" },
  { value: "mad", label: "maduresisk" },
  { value: "maf", label: "mafa" },
  { value: "mag", label: "magahi" },
  { value: "vmf", label: "Main-Franconian" },
  { value: "mai", label: "maithili" },
  { value: "mak", label: "makasar" },
  { value: "mk", label: "makedonsk" },
  { value: "mgh", label: "makhuwa-meetto" },
  { value: "kde", label: "makonde" },
  { value: "ml", label: "malayalam" },
  { value: "ms", label: "malayisk" },
  { value: "mt", label: "maltesisk" },
  { value: "mdr", label: "mandar" },
  { value: "man", label: "mandingo" },
  { value: "mnc", label: "mandsju" },
  { value: "mni", label: "manipuri" },
  { value: "gv", label: "mansk" },
  { value: "mi", label: "maori" },
  { value: "mr", label: "marathi" },
  { value: "chm", label: "mari" },
  { value: "mh", label: "marshallesisk" },
  { value: "mwr", label: "marwari" },
  { value: "mas", label: "masai" },
  { value: "mfe", label: "mauritisk-kreolsk" },
  { value: "mzn", label: "Mazanderani" },
  { value: "byv", label: "medumba" },
  { value: "es_MX", label: "meksikansk spansk" },
  { value: "enm", label: "mellomengelsk" },
  { value: "frm", label: "mellomfransk" },
  { value: "gmh", label: "mellomh\u00f8ytysk" },
  { value: "mga", label: "mellomirsk" },
  { value: "dum", label: "mellomnederlandsk" },
  { value: "men", label: "mende" },
  { value: "mwv", label: "Mentawai" },
  { value: "mer", label: "meru" },
  { value: "mgo", label: "meta\u2019" },
  { value: "mic", label: "micmac" },
  { value: "nan", label: "Min Nan Chinese" },
  { value: "min", label: "minangkabau" },
  { value: "xmf", label: "Mingrelian" },
  { value: "mwl", label: "mirandesisk" },
  { value: "ar_001", label: "moderne standard arabisk" },
  { value: "moh", label: "mohawk" },
  { value: "mdf", label: "moksha" },
  { value: "ro_MD", label: "moldovsk" },
  { value: "lol", label: "mongo" },
  { value: "mn", label: "mongolsk" },
  { value: "ary", label: "Moroccan Arabic" },
  { value: "mos", label: "mossi" },
  { value: "mua", label: "mundang" },
  { value: "ttt", label: "Muslim Tat" },
  { value: "mye", label: "myene" },
  { value: "naq", label: "nama" },
  { value: "nap", label: "napolitansk" },
  { value: "na", label: "nauru" },
  { value: "nv", label: "navajo" },
  { value: "ng", label: "ndonga" },
  { value: "nl", label: "nederlandsk" },
  { value: "ne", label: "nepali" },
  { value: "new", label: "newari" },
  { value: "sba", label: "ngambay" },
  { value: "nnh", label: "ngiemboon" },
  { value: "jgo", label: "ngomba" },
  { value: "yrl", label: "Nheengatu" },
  { value: "nia", label: "nias" },
  { value: "niu", label: "niueansk" },
  { value: "nqo", label: "nk\u00e5" },
  { value: "nog", label: "nogai" },
  { value: "nd", label: "nord-ndebele" },
  { value: "nso", label: "nord-sotho" },
  { value: "frr", label: "nordfrisisk" },
  { value: "se", label: "nordsamisk" },
  { value: "no", label: "norsk" },
  { value: "nb", label: "norsk bokm\u00e5l" },
  { value: "nn", label: "norsk nynorsk" },
  { value: "nov", label: "Novial" },
  { value: "nus", label: "nuer" },
  { value: "nym", label: "nyamwezi" },
  { value: "ny", label: "nyanja" },
  { value: "nyn", label: "nyankole" },
  { value: "tog", label: "nyasa-tongansk" },
  { value: "nyo", label: "nyoro" },
  { value: "nzi", label: "nzima" },
  { value: "oj", label: "ojibwa" },
  { value: "oc", label: "oksitansk" },
  { value: "or", label: "oriya" },
  { value: "om", label: "oromo" },
  { value: "osa", label: "osage" },
  { value: "os", label: "ossetisk" },
  { value: "ota", label: "ottomansk tyrkisk" },
  { value: "pal", label: "pahlavi" },
  { value: "pfl", label: "Palatine German" },
  { value: "pau", label: "palauisk" },
  { value: "pi", label: "pali" },
  { value: "pam", label: "pampanga" },
  { value: "pag", label: "pangasinan" },
  { value: "pap", label: "papiamento" },
  { value: "ps", label: "pashto" },
  { value: "pdc", label: "Pennsylvania German" },
  { value: "fa", label: "persisk" },
  { value: "pcd", label: "Picard" },
  { value: "pms", label: "Piedmontese" },
  { value: "pdt", label: "Plautdietsch" },
  { value: "pl", label: "polsk" },
  { value: "pon", label: "ponapisk" },
  { value: "pnt", label: "Pontic" },
  { value: "pt", label: "portugisisk" },
  { value: "prg", label: "Prussian" },
  { value: "pa", label: "punjabi" },
  { value: "qu", label: "quechua" },
  { value: "quc", label: "quich\u00e9" },
  { value: "raj", label: "rajasthani" },
  { value: "rap", label: "rapanui" },
  { value: "rar", label: "rarotongansk" },
  { value: "rm", label: "retoromansk" },
  { value: "rif", label: "Riffian" },
  { value: "rgn", label: "Romagnol" },
  { value: "rom", label: "romani" },
  { value: "rof", label: "rombo" },
  { value: "root", label: "rot" },
  { value: "rtm", label: "Rotuman" },
  { value: "rug", label: "Roviana" },
  { value: "ro", label: "rumensk" },
  { value: "rn", label: "rundi" },
  { value: "ru", label: "russisk" },
  { value: "rue", label: "Rusyn" },
  { value: "rwk", label: "rwa" },
  { value: "ssy", label: "saho" },
  { value: "sam", label: "samaritansk arameisk" },
  { value: "saq", label: "samburu" },
  { value: "sm", label: "samoansk" },
  { value: "sgs", label: "Samogitian" },
  { value: "sad", label: "sandawe" },
  { value: "sg", label: "sango" },
  { value: "sbp", label: "sangu" },
  { value: "sa", label: "sanskrit" },
  { value: "sat", label: "santali" },
  { value: "sc", label: "sardinsk" },
  { value: "sas", label: "sasak" },
  { value: "sdc", label: "Sassarese Sardinian" },
  { value: "stq", label: "Saterland Frisian" },
  { value: "saz", label: "Saurashtra" },
  { value: "sly", label: "Selayar" },
  { value: "sel", label: "selkupisk" },
  { value: "seh", label: "sena" },
  { value: "see", label: "seneca" },
  { value: "tzm", label: "sentralmarokkansk tamazight" },
  { value: "sr", label: "serbisk" },
  { value: "sh", label: "serbokroatisk" },
  { value: "srr", label: "serer" },
  { value: "sei", label: "Seri" },
  { value: "tn", label: "setswana" },
  { value: "ksb", label: "shambala" },
  { value: "shn", label: "shan" },
  { value: "sn", label: "shona" },
  { value: "ii", label: "sichuan-yi" },
  { value: "scn", label: "siciliansk" },
  { value: "sid", label: "sidamo" },
  { value: "bla", label: "siksika" },
  { value: "szl", label: "Silesian" },
  { value: "sd", label: "sindhi" },
  { value: "si", label: "singalesisk" },
  { value: "sms", label: "skoltesamisk" },
  { value: "sco", label: "skotsk" },
  { value: "gd", label: "skotsk g\u00e6lisk" },
  { value: "den", label: "slavisk" },
  { value: "sk", label: "slovakisk" },
  { value: "sl", label: "slovensk" },
  { value: "xog", label: "soga" },
  { value: "sog", label: "sogdisk" },
  { value: "so", label: "somali" },
  { value: "snk", label: "soninke" },
  { value: "azb", label: "South Azerbaijani" },
  { value: "es", label: "spansk" },
  { value: "srn", label: "sranan tongo" },
  { value: "zgh", label: "standard marrokansk tamazight" },
  { value: "suk", label: "sukuma" },
  { value: "sux", label: "sumerisk" },
  { value: "su", label: "sundanesisk" },
  { value: "sus", label: "susu" },
  { value: "gsw", label: "sveitsertysk" },
  { value: "fr_CH", label: "sveitsisk fransk" },
  { value: "de_CH", label: "sveitsisk h\u00f8ytysk" },
  { value: "sv", label: "svensk" },
  { value: "sw", label: "swahili" },
  { value: "ss", label: "swati" },
  { value: "syr", label: "syrisk" },
  { value: "nr", label: "s\u00f8r-ndebele" },
  { value: "st", label: "s\u00f8r-sotho" },
  { value: "alt", label: "s\u00f8raltaisk" },
  { value: "sma", label: "s\u00f8rsamisk" },
  { value: "shi", label: "tachelhit" },
  { value: "tg", label: "tadsjikisk" },
  { value: "tl", label: "tagalog" },
  { value: "ty", label: "tahitisk" },
  { value: "dav", label: "taita" },
  { value: "tly", label: "Talysh" },
  { value: "tmh", label: "tamasjek" },
  { value: "ta", label: "tamilsk" },
  { value: "trv", label: "taroko" },
  { value: "twq", label: "tasawaq" },
  { value: "tt", label: "tatarisk" },
  { value: "te", label: "telugu" },
  { value: "tem", label: "temne" },
  { value: "ter", label: "tereno" },
  { value: "teo", label: "teso" },
  { value: "tet", label: "tetum" },
  { value: "th", label: "thai" },
  { value: "bo", label: "tibetansk" },
  { value: "tig", label: "tigr\u00e9" },
  { value: "ti", label: "tigrinja" },
  { value: "tiv", label: "tiv" },
  { value: "tli", label: "tlingit" },
  { value: "tpi", label: "tok pisin" },
  { value: "tkl", label: "tokelau" },
  { value: "to", label: "tongansk" },
  { value: "fit", label: "Tornedalen Finnish" },
  { value: "zh_Hant", label: "tradisjonell kinesisk" },
  { value: "tkr", label: "Tsakhur" },
  { value: "tsd", label: "Tsakonian" },
  { value: "tsi", label: "tsimshian" },
  { value: "shu", label: "Tsjad-arabisk" },
  { value: "cs", label: "tsjekkisk" },
  { value: "ce", label: "tsjetsjensk" },
  { value: "cv", label: "tsjuvansk" },
  { value: "ts", label: "tsonga" },
  { value: "tcy", label: "Tulu" },
  { value: "tum", label: "tumbuka" },
  { value: "aeb", label: "Tunisian Arabic" },
  { value: "tk", label: "turkmensk" },
  { value: "tru", label: "Turoyo" },
  { value: "tvl", label: "tuvalu" },
  { value: "tyv", label: "tuvinisk" },
  { value: "tw", label: "twi" },
  { value: "kcg", label: "tyap" },
  { value: "tr", label: "tyrkisk" },
  { value: "de", label: "tysk" },
  { value: "udm", label: "udmurt" },
  { value: "uga", label: "ugaritisk" },
  { value: "ug", label: "uigurisk" },
  { value: "und", label: "ukjent spr\u00e5k" },
  { value: "uk", label: "ukrainsk" },
  { value: "umb", label: "umbundu" },
  { value: "hu", label: "ungarsk" },
  { value: "ur", label: "urdu" },
  { value: "uz", label: "usbekisk" },
  { value: "zxx", label: "uten spr\u00e5klig innhold" },
  { value: "vai", label: "vai" },
  { value: "wa", label: "vallonsk" },
  { value: "ve", label: "venda" },
  { value: "vec", label: "Venetian" },
  { value: "vep", label: "Veps" },
  { value: "fy", label: "vestfrisisk" },
  { value: "vi", label: "vietnamesisk" },
  { value: "vo", label: "volapyk" },
  { value: "vro", label: "V\u00f5ro" },
  { value: "vot", label: "votisk" },
  { value: "vun", label: "vunjo" },
  { value: "wal", label: "walamo" },
  { value: "cy", label: "walisisk" },
  { value: "wae", label: "walser" },
  { value: "war", label: "waray" },
  { value: "wbp", label: "Warlpiri" },
  { value: "was", label: "washo" },
  { value: "guc", label: "Wayuu" },
  { value: "vls", label: "West Flemish" },
  { value: "mrj", label: "Western Mari" },
  { value: "wo", label: "wolof" },
  { value: "wuu", label: "Wu Chinese" },
  { value: "xh", label: "xhosa" },
  { value: "hsn", label: "Xiang Chinese" },
  { value: "yav", label: "yangben" },
  { value: "yao", label: "yao" },
  { value: "yap", label: "yapesisk" },
  { value: "ybb", label: "yemba" },
  { value: "zap", label: "zapotec" },
  { value: "dje", label: "zarma" },
  { value: "zza", label: "zaza" },
  { value: "zea", label: "Zeelandic" },
  { value: "zen", label: "zenaga" },
  { value: "za", label: "zhuang" },
  { value: "gbz", label: "Zoroastrian Dari" },
  { value: "zu", label: "zulu" },
  { value: "zun", label: "zuni" },
  { value: "de_AT", label: "\u00f8sterriksk tysk" },
  { value: "frs", label: "\u00f8stfrisisk" },
];
