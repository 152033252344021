import React from "react";
import { Anchor, AnchorProps } from "@mantine/core";
import { createLink, CreateLinkProps, Link } from "@tanstack/react-router";

/**
 * Wrapper around `Anchor` that uses `Link` as the root component.
 * Allows for stylying with `Anchor` but uses typesafety of `Link`.
 */
export const AnchorLink = createLink(
  // eslint-disable-next-line react/display-name
  React.forwardRef(
    (
      props: Omit<CreateLinkProps, "children"> & AnchorProps,
      ref: React.ForwardedRef<HTMLAnchorElement>,
    ) => {
      return <Anchor {...props} ref={ref} renderRoot={Link} />;
    },
  ),
);
