import { Trans } from "@lingui/macro";
import { Box, Flex } from "@mantine/core";
import { createFileRoute } from "@tanstack/react-router";

import CreateCategory from "@/features/product-categories/create-product-category/create-category-modal";
import CategoriesTable from "@/features/product-categories/product-categories-table";

export const Route = createFileRoute("/_auth-layout/products/categories/")({
  component: CategoriesPage,
});

function CategoriesPage() {
  return (
    <Box p="lg">
      <Flex align="center" justify="space-between">
        <h1>
          <Trans>Kategorier</Trans>
        </h1>

        <CreateCategory />
      </Flex>

      <CategoriesTable />
    </Box>
  );
}
