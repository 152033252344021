import { useEffect } from "react";
import { useUser } from "@clerk/clerk-react";
import * as Sentry from "@sentry/react";

export function useSetSentryUser() {
  const user = useUser();

  useEffect(() => {
    const userObj = user.user;
    if (userObj) {
      Sentry.setUser({
        id: userObj.id,
        email: userObj.emailAddresses[0].emailAddress,
        username:
          (userObj.fullName ?? (userObj.firstName || userObj.lastName))
            ? `${userObj.firstName} ${userObj.lastName}`
            : (userObj.username ?? undefined),
      });
    } else {
      Sentry.setUser(null);
    }
  }, [user.user]);
}
