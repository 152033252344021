/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as AuthLayoutImport } from './routes/_auth-layout'
import { Route as AuthLayoutIndexImport } from './routes/_auth-layout/index'
import { Route as SignUpSplatImport } from './routes/sign-up/$'
import { Route as LoginSplatImport } from './routes/login/$'
import { Route as AuthLayoutProductsIndexImport } from './routes/_auth-layout/products/index'
import { Route as AuthLayoutLocationsIndexImport } from './routes/_auth-layout/locations/index'
import { Route as AuthLayoutEmployeesIndexImport } from './routes/_auth-layout/employees/index'
import { Route as AuthLayoutCustomersIndexImport } from './routes/_auth-layout/customers/index'
import { Route as AuthLayoutBookingsIndexImport } from './routes/_auth-layout/bookings/index'
import { Route as AuthLayoutProductsCategoriesIndexImport } from './routes/_auth-layout/products/categories/index'
import { Route as AuthLayoutEmployeesEmployeeIdIndexImport } from './routes/_auth-layout/employees/$employeeId/index'
import { Route as AuthLayoutCustomersCustomerIdIndexImport } from './routes/_auth-layout/customers/$customerId/index'
import { Route as AuthLayoutBookingsBookingIdIndexImport } from './routes/_auth-layout/bookings/$bookingId/index'

// Create/Update Routes

const AuthLayoutRoute = AuthLayoutImport.update({
  id: '/_auth-layout',
  getParentRoute: () => rootRoute,
} as any)

const AuthLayoutIndexRoute = AuthLayoutIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => AuthLayoutRoute,
} as any)

const SignUpSplatRoute = SignUpSplatImport.update({
  id: '/sign-up/$',
  path: '/sign-up/$',
  getParentRoute: () => rootRoute,
} as any)

const LoginSplatRoute = LoginSplatImport.update({
  id: '/login/$',
  path: '/login/$',
  getParentRoute: () => rootRoute,
} as any)

const AuthLayoutProductsIndexRoute = AuthLayoutProductsIndexImport.update({
  id: '/products/',
  path: '/products/',
  getParentRoute: () => AuthLayoutRoute,
} as any)

const AuthLayoutLocationsIndexRoute = AuthLayoutLocationsIndexImport.update({
  id: '/locations/',
  path: '/locations/',
  getParentRoute: () => AuthLayoutRoute,
} as any)

const AuthLayoutEmployeesIndexRoute = AuthLayoutEmployeesIndexImport.update({
  id: '/employees/',
  path: '/employees/',
  getParentRoute: () => AuthLayoutRoute,
} as any)

const AuthLayoutCustomersIndexRoute = AuthLayoutCustomersIndexImport.update({
  id: '/customers/',
  path: '/customers/',
  getParentRoute: () => AuthLayoutRoute,
} as any)

const AuthLayoutBookingsIndexRoute = AuthLayoutBookingsIndexImport.update({
  id: '/bookings/',
  path: '/bookings/',
  getParentRoute: () => AuthLayoutRoute,
} as any)

const AuthLayoutProductsCategoriesIndexRoute =
  AuthLayoutProductsCategoriesIndexImport.update({
    id: '/products/categories/',
    path: '/products/categories/',
    getParentRoute: () => AuthLayoutRoute,
  } as any)

const AuthLayoutEmployeesEmployeeIdIndexRoute =
  AuthLayoutEmployeesEmployeeIdIndexImport.update({
    id: '/employees/$employeeId/',
    path: '/employees/$employeeId/',
    getParentRoute: () => AuthLayoutRoute,
  } as any)

const AuthLayoutCustomersCustomerIdIndexRoute =
  AuthLayoutCustomersCustomerIdIndexImport.update({
    id: '/customers/$customerId/',
    path: '/customers/$customerId/',
    getParentRoute: () => AuthLayoutRoute,
  } as any)

const AuthLayoutBookingsBookingIdIndexRoute =
  AuthLayoutBookingsBookingIdIndexImport.update({
    id: '/bookings/$bookingId/',
    path: '/bookings/$bookingId/',
    getParentRoute: () => AuthLayoutRoute,
  } as any)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/_auth-layout': {
      id: '/_auth-layout'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof AuthLayoutImport
      parentRoute: typeof rootRoute
    }
    '/login/$': {
      id: '/login/$'
      path: '/login/$'
      fullPath: '/login/$'
      preLoaderRoute: typeof LoginSplatImport
      parentRoute: typeof rootRoute
    }
    '/sign-up/$': {
      id: '/sign-up/$'
      path: '/sign-up/$'
      fullPath: '/sign-up/$'
      preLoaderRoute: typeof SignUpSplatImport
      parentRoute: typeof rootRoute
    }
    '/_auth-layout/': {
      id: '/_auth-layout/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof AuthLayoutIndexImport
      parentRoute: typeof AuthLayoutImport
    }
    '/_auth-layout/bookings/': {
      id: '/_auth-layout/bookings/'
      path: '/bookings'
      fullPath: '/bookings'
      preLoaderRoute: typeof AuthLayoutBookingsIndexImport
      parentRoute: typeof AuthLayoutImport
    }
    '/_auth-layout/customers/': {
      id: '/_auth-layout/customers/'
      path: '/customers'
      fullPath: '/customers'
      preLoaderRoute: typeof AuthLayoutCustomersIndexImport
      parentRoute: typeof AuthLayoutImport
    }
    '/_auth-layout/employees/': {
      id: '/_auth-layout/employees/'
      path: '/employees'
      fullPath: '/employees'
      preLoaderRoute: typeof AuthLayoutEmployeesIndexImport
      parentRoute: typeof AuthLayoutImport
    }
    '/_auth-layout/locations/': {
      id: '/_auth-layout/locations/'
      path: '/locations'
      fullPath: '/locations'
      preLoaderRoute: typeof AuthLayoutLocationsIndexImport
      parentRoute: typeof AuthLayoutImport
    }
    '/_auth-layout/products/': {
      id: '/_auth-layout/products/'
      path: '/products'
      fullPath: '/products'
      preLoaderRoute: typeof AuthLayoutProductsIndexImport
      parentRoute: typeof AuthLayoutImport
    }
    '/_auth-layout/bookings/$bookingId/': {
      id: '/_auth-layout/bookings/$bookingId/'
      path: '/bookings/$bookingId'
      fullPath: '/bookings/$bookingId'
      preLoaderRoute: typeof AuthLayoutBookingsBookingIdIndexImport
      parentRoute: typeof AuthLayoutImport
    }
    '/_auth-layout/customers/$customerId/': {
      id: '/_auth-layout/customers/$customerId/'
      path: '/customers/$customerId'
      fullPath: '/customers/$customerId'
      preLoaderRoute: typeof AuthLayoutCustomersCustomerIdIndexImport
      parentRoute: typeof AuthLayoutImport
    }
    '/_auth-layout/employees/$employeeId/': {
      id: '/_auth-layout/employees/$employeeId/'
      path: '/employees/$employeeId'
      fullPath: '/employees/$employeeId'
      preLoaderRoute: typeof AuthLayoutEmployeesEmployeeIdIndexImport
      parentRoute: typeof AuthLayoutImport
    }
    '/_auth-layout/products/categories/': {
      id: '/_auth-layout/products/categories/'
      path: '/products/categories'
      fullPath: '/products/categories'
      preLoaderRoute: typeof AuthLayoutProductsCategoriesIndexImport
      parentRoute: typeof AuthLayoutImport
    }
  }
}

// Create and export the route tree

interface AuthLayoutRouteChildren {
  AuthLayoutIndexRoute: typeof AuthLayoutIndexRoute
  AuthLayoutBookingsIndexRoute: typeof AuthLayoutBookingsIndexRoute
  AuthLayoutCustomersIndexRoute: typeof AuthLayoutCustomersIndexRoute
  AuthLayoutEmployeesIndexRoute: typeof AuthLayoutEmployeesIndexRoute
  AuthLayoutLocationsIndexRoute: typeof AuthLayoutLocationsIndexRoute
  AuthLayoutProductsIndexRoute: typeof AuthLayoutProductsIndexRoute
  AuthLayoutBookingsBookingIdIndexRoute: typeof AuthLayoutBookingsBookingIdIndexRoute
  AuthLayoutCustomersCustomerIdIndexRoute: typeof AuthLayoutCustomersCustomerIdIndexRoute
  AuthLayoutEmployeesEmployeeIdIndexRoute: typeof AuthLayoutEmployeesEmployeeIdIndexRoute
  AuthLayoutProductsCategoriesIndexRoute: typeof AuthLayoutProductsCategoriesIndexRoute
}

const AuthLayoutRouteChildren: AuthLayoutRouteChildren = {
  AuthLayoutIndexRoute: AuthLayoutIndexRoute,
  AuthLayoutBookingsIndexRoute: AuthLayoutBookingsIndexRoute,
  AuthLayoutCustomersIndexRoute: AuthLayoutCustomersIndexRoute,
  AuthLayoutEmployeesIndexRoute: AuthLayoutEmployeesIndexRoute,
  AuthLayoutLocationsIndexRoute: AuthLayoutLocationsIndexRoute,
  AuthLayoutProductsIndexRoute: AuthLayoutProductsIndexRoute,
  AuthLayoutBookingsBookingIdIndexRoute: AuthLayoutBookingsBookingIdIndexRoute,
  AuthLayoutCustomersCustomerIdIndexRoute:
    AuthLayoutCustomersCustomerIdIndexRoute,
  AuthLayoutEmployeesEmployeeIdIndexRoute:
    AuthLayoutEmployeesEmployeeIdIndexRoute,
  AuthLayoutProductsCategoriesIndexRoute:
    AuthLayoutProductsCategoriesIndexRoute,
}

const AuthLayoutRouteWithChildren = AuthLayoutRoute._addFileChildren(
  AuthLayoutRouteChildren,
)

export interface FileRoutesByFullPath {
  '': typeof AuthLayoutRouteWithChildren
  '/login/$': typeof LoginSplatRoute
  '/sign-up/$': typeof SignUpSplatRoute
  '/': typeof AuthLayoutIndexRoute
  '/bookings': typeof AuthLayoutBookingsIndexRoute
  '/customers': typeof AuthLayoutCustomersIndexRoute
  '/employees': typeof AuthLayoutEmployeesIndexRoute
  '/locations': typeof AuthLayoutLocationsIndexRoute
  '/products': typeof AuthLayoutProductsIndexRoute
  '/bookings/$bookingId': typeof AuthLayoutBookingsBookingIdIndexRoute
  '/customers/$customerId': typeof AuthLayoutCustomersCustomerIdIndexRoute
  '/employees/$employeeId': typeof AuthLayoutEmployeesEmployeeIdIndexRoute
  '/products/categories': typeof AuthLayoutProductsCategoriesIndexRoute
}

export interface FileRoutesByTo {
  '/login/$': typeof LoginSplatRoute
  '/sign-up/$': typeof SignUpSplatRoute
  '/': typeof AuthLayoutIndexRoute
  '/bookings': typeof AuthLayoutBookingsIndexRoute
  '/customers': typeof AuthLayoutCustomersIndexRoute
  '/employees': typeof AuthLayoutEmployeesIndexRoute
  '/locations': typeof AuthLayoutLocationsIndexRoute
  '/products': typeof AuthLayoutProductsIndexRoute
  '/bookings/$bookingId': typeof AuthLayoutBookingsBookingIdIndexRoute
  '/customers/$customerId': typeof AuthLayoutCustomersCustomerIdIndexRoute
  '/employees/$employeeId': typeof AuthLayoutEmployeesEmployeeIdIndexRoute
  '/products/categories': typeof AuthLayoutProductsCategoriesIndexRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/_auth-layout': typeof AuthLayoutRouteWithChildren
  '/login/$': typeof LoginSplatRoute
  '/sign-up/$': typeof SignUpSplatRoute
  '/_auth-layout/': typeof AuthLayoutIndexRoute
  '/_auth-layout/bookings/': typeof AuthLayoutBookingsIndexRoute
  '/_auth-layout/customers/': typeof AuthLayoutCustomersIndexRoute
  '/_auth-layout/employees/': typeof AuthLayoutEmployeesIndexRoute
  '/_auth-layout/locations/': typeof AuthLayoutLocationsIndexRoute
  '/_auth-layout/products/': typeof AuthLayoutProductsIndexRoute
  '/_auth-layout/bookings/$bookingId/': typeof AuthLayoutBookingsBookingIdIndexRoute
  '/_auth-layout/customers/$customerId/': typeof AuthLayoutCustomersCustomerIdIndexRoute
  '/_auth-layout/employees/$employeeId/': typeof AuthLayoutEmployeesEmployeeIdIndexRoute
  '/_auth-layout/products/categories/': typeof AuthLayoutProductsCategoriesIndexRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | ''
    | '/login/$'
    | '/sign-up/$'
    | '/'
    | '/bookings'
    | '/customers'
    | '/employees'
    | '/locations'
    | '/products'
    | '/bookings/$bookingId'
    | '/customers/$customerId'
    | '/employees/$employeeId'
    | '/products/categories'
  fileRoutesByTo: FileRoutesByTo
  to:
    | '/login/$'
    | '/sign-up/$'
    | '/'
    | '/bookings'
    | '/customers'
    | '/employees'
    | '/locations'
    | '/products'
    | '/bookings/$bookingId'
    | '/customers/$customerId'
    | '/employees/$employeeId'
    | '/products/categories'
  id:
    | '__root__'
    | '/_auth-layout'
    | '/login/$'
    | '/sign-up/$'
    | '/_auth-layout/'
    | '/_auth-layout/bookings/'
    | '/_auth-layout/customers/'
    | '/_auth-layout/employees/'
    | '/_auth-layout/locations/'
    | '/_auth-layout/products/'
    | '/_auth-layout/bookings/$bookingId/'
    | '/_auth-layout/customers/$customerId/'
    | '/_auth-layout/employees/$employeeId/'
    | '/_auth-layout/products/categories/'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  AuthLayoutRoute: typeof AuthLayoutRouteWithChildren
  LoginSplatRoute: typeof LoginSplatRoute
  SignUpSplatRoute: typeof SignUpSplatRoute
}

const rootRouteChildren: RootRouteChildren = {
  AuthLayoutRoute: AuthLayoutRouteWithChildren,
  LoginSplatRoute: LoginSplatRoute,
  SignUpSplatRoute: SignUpSplatRoute,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/_auth-layout",
        "/login/$",
        "/sign-up/$"
      ]
    },
    "/_auth-layout": {
      "filePath": "_auth-layout.tsx",
      "children": [
        "/_auth-layout/",
        "/_auth-layout/bookings/",
        "/_auth-layout/customers/",
        "/_auth-layout/employees/",
        "/_auth-layout/locations/",
        "/_auth-layout/products/",
        "/_auth-layout/bookings/$bookingId/",
        "/_auth-layout/customers/$customerId/",
        "/_auth-layout/employees/$employeeId/",
        "/_auth-layout/products/categories/"
      ]
    },
    "/login/$": {
      "filePath": "login/$.tsx"
    },
    "/sign-up/$": {
      "filePath": "sign-up/$.tsx"
    },
    "/_auth-layout/": {
      "filePath": "_auth-layout/index.tsx",
      "parent": "/_auth-layout"
    },
    "/_auth-layout/bookings/": {
      "filePath": "_auth-layout/bookings/index.tsx",
      "parent": "/_auth-layout"
    },
    "/_auth-layout/customers/": {
      "filePath": "_auth-layout/customers/index.tsx",
      "parent": "/_auth-layout"
    },
    "/_auth-layout/employees/": {
      "filePath": "_auth-layout/employees/index.tsx",
      "parent": "/_auth-layout"
    },
    "/_auth-layout/locations/": {
      "filePath": "_auth-layout/locations/index.tsx",
      "parent": "/_auth-layout"
    },
    "/_auth-layout/products/": {
      "filePath": "_auth-layout/products/index.tsx",
      "parent": "/_auth-layout"
    },
    "/_auth-layout/bookings/$bookingId/": {
      "filePath": "_auth-layout/bookings/$bookingId/index.tsx",
      "parent": "/_auth-layout"
    },
    "/_auth-layout/customers/$customerId/": {
      "filePath": "_auth-layout/customers/$customerId/index.tsx",
      "parent": "/_auth-layout"
    },
    "/_auth-layout/employees/$employeeId/": {
      "filePath": "_auth-layout/employees/$employeeId/index.tsx",
      "parent": "/_auth-layout"
    },
    "/_auth-layout/products/categories/": {
      "filePath": "_auth-layout/products/categories/index.tsx",
      "parent": "/_auth-layout"
    }
  }
}
ROUTE_MANIFEST_END */
