import { t, Trans } from "@lingui/macro";
import {
  Button,
  Group,
  Modal,
  NumberInput,
  Select,
  Stack,
  Switch,
  Text,
  TextInput,
  Title,
} from "@mantine/core";
import { TimeInput } from "@mantine/dates";
import { useForm, zodResolver } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { IconPlus } from "@tabler/icons-react";
import { useQueryClient } from "@tanstack/react-query";

import { useGetCategories } from "@/shared/api/generated/categories";
import { useGetLocations } from "@/shared/api/generated/locations";
import {
  getGetProductsQueryKey,
  useCreateProduct,
} from "@/shared/api/generated/products";
import { useFormWatch } from "@/shared/utils/forms/useFormWatch";

import { productDurationToMinutes } from "../utils/product-duration";
import {
  newProductFormSchema,
  type NewProductFormInitial,
  type NewProductFormSubmit,
} from "./form-schema";

export default function CreateProduct() {
  const [opened, { open, close }] = useDisclosure(false);

  const form = useForm<
    NewProductFormInitial,
    (v: NewProductFormInitial) => NewProductFormSubmit
  >({
    mode: "uncontrolled",
    initialValues: {
      locationId: null,
      nameEnglish: "",
      nameNorwegian: "",
      categoryId: null,
      withPrice: true,
      price: 0,
      withDuration: true,
      duration: "",
    },
    validate: zodResolver(newProductFormSchema),
  });

  const queryClient = useQueryClient();

  const { mutateAsync: createProduct } = useCreateProduct();

  async function handleSubmit(values: NewProductFormSubmit) {
    const durationInMinutes = withDuration
      ? productDurationToMinutes(values.duration)
      : null;

    await createProduct(
      {
        data: {
          locationID: values.locationId,
          nameEN: values.nameEnglish,
          nameNO: values.nameNorwegian,
          categoryID: values.categoryId,
          duration: durationInMinutes,
          price: values.withPrice ? values.price : null,
        },
      },
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries({
            queryKey: getGetProductsQueryKey(),
          });
          close();
        },
      },
    );
  }

  const withPrice = useFormWatch(form, "withPrice");
  const withDuration = useFormWatch(form, "withDuration");

  const { data: locations } = useGetLocations();
  const { data: productCategories } = useGetCategories();

  return (
    <>
      <Button onClick={open} leftSection={<IconPlus />}>
        <Trans>Opprett produkt</Trans>
      </Button>

      <Modal
        opened={opened}
        onClose={close}
        size="lg"
        title={
          <Title size="sm">
            <Trans>Opprett produkt</Trans>
          </Title>
        }
        transitionProps={{
          onExited: () => form.reset(),
        }}
      >
        <Text size="sm" c="dimmed" mb={8}>
          <Trans>Standardverdier. Alt kan overstyres i enkelt-bookinger.</Trans>
        </Text>

        <form onSubmit={form.onSubmit(handleSubmit)}>
          <Stack>
            <Select
              label={t`Lokasjon`}
              placeholder={t`Velg lokasjon`}
              data={locations?.data?.map((location) => ({
                value: location.id,
                label: location.name,
              }))}
              searchable
              withAsterisk
              clearable
              description={t`Produktet kan kun gjelde for en lokasjon om gangen, for å muliggjøre forskjellig prising eller varighet per lokasjon. Dersom samme type produkt skal gjelde på flere lokasjoner, må det opprettes ett produkt per lokasjon.`}
              {...form.getInputProps("locationId")}
            />

            <Group grow align="flex-start">
              <TextInput
                label={t`Navn (norsk)`}
                placeholder={t`Velg navn`}
                withAsterisk
                {...form.getInputProps("nameNorwegian")}
              />
              <TextInput
                label={t`Navn (engelsk)`}
                placeholder={t`Velg navn`}
                withAsterisk
                {...form.getInputProps("nameEnglish")}
              />
            </Group>

            <Group grow align="flex-start">
              <Select
                label={t`Kategori`}
                placeholder={t`Kategori`}
                data={productCategories?.data?.map((category) => ({
                  value: category.id,
                  label: category.name,
                }))}
                searchable
                withAsterisk
                clearable
                description={t`Velg kategorien som passer best for dette produktet.`}
                {...form.getInputProps("categoryId")}
              />
            </Group>

            <Group grow align="flex-start">
              <Switch
                mt={33}
                label={t`Har en varighet`}
                {...form.getInputProps("withDuration", { type: "checkbox" })}
              />
              <TimeInput
                label={t`Varighet`}
                withAsterisk={withDuration}
                disabled={!withDuration}
                {...form.getInputProps("duration")}
              />
            </Group>

            <Group grow align="flex-start">
              <Switch
                mt={33}
                label={t`Har en pris`}
                {...form.getInputProps("withPrice", { type: "checkbox" })}
              />
              <NumberInput
                label={t`Pris per stykk`}
                withAsterisk={withPrice}
                disabled={!withPrice}
                allowNegative={false}
                allowDecimal={false}
                hideControls
                {...form.getInputProps("price")}
              />
            </Group>

            <Group justify="end" gap={8} mt={8}>
              <Button variant="subtle" onClick={close}>
                <Trans>Avbryt</Trans>
              </Button>
              <Button type="submit">
                <Trans>Opprett</Trans>
              </Button>
            </Group>
          </Stack>
        </form>
      </Modal>
    </>
  );
}
