import { t } from "@lingui/macro";
import { z, ZodTypeAny } from "zod";

/**
 * Handy when form input is allowed to be null (i.e. for initial state), but on submit value is required
 * 
 * @example
 * const FormSchema = z.object({
     date: nullableInput(z.date({ message: t`Velg en dato` }), t`Velg en dato`),
   });

   type FormInitial = z.input<typeof FormSchema>;
        // ^ { date: Date | null; }
   type FormSubmit = z.output<typeof FormSchema>;
        // ^ { date: Date; }

    // later in component, to get full typesafety
   
    const form = useForm<FormInitial, (v: FormInitial) => FormSubmit>
 * 
 * Source: https://github.com/colinhacks/zod/issues/1206#issuecomment-2163952886
 */
export const nullableInput = <T extends ZodTypeAny>(
  schema: T,
  message = t`Kan ikke være tom`,
) => {
  return schema.nullable().transform((val, ctx) => {
    if (val === null) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        fatal: true,
        message,
      });

      return z.NEVER;
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return val;
  });
};
