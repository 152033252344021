export const locationsInfo = [
  {
    name: "Narvik Museum",
    street: "Administrasjonsveien 3",
    postalCode: "8514",
    city: "Narvik",
    phone: "+47 76 96 96 50",
    email: "narvik@museumnord.no",
    logoFilename: "narvik-museum.png",
  },
  {
    name: "Norsk Fiskeværsmuseum",
    street: "Å vegen 21",
    postalCode: "8392",
    city: "Sørvågen",
    phone: "+ 47 76 09 14 88",
    email: "nfmbooking@museumnord.no",
    logoFilename: "norsk-fiskevaersmuseum.png",
  },
  {
    name: "Lofotakvariet",
    street: "Storvåganveien 28",
    postalCode: "8310",
    city: "Kabelvåg",
    phone: "+47 76 07 86 65",
    email: "lofotakvariet@museumnord.no",
    logoFilename: null,
  },
  {
    name: "Lofotmuseet",
    street: "Storvåganveien 25",
    postalCode: "8310",
    city: "Kabelvåg",
    phone: "+47 901 72 077",
    email: "lofotmuseet@museumnord.no",
    logoFilename: null,
  },
  {
    name: "Hurtigrutemuseet",
    street: "Richard Withs plass 1",
    postalCode: "8450",
    city: "Stokmarknes",
    phone: "+47 909 96 412",
    email: "post@hurtigrutemuseet.no",
    logoFilename: null,
  },
  {
    name: "Galleri Espolin",
    street: "Storvåganveien 22",
    postalCode: "8310",
    city: "Kabelvåg",
    phone: "+47 76 07 84 05",
    email: "galleri-espolin@museumnord.no",
    logoFilename: null,
  },
  {
    name: "Lofotr Vikingmuseum",
    street: "Prestegårdsveien 4",
    postalCode: "8360",
    city: "Bøstad",
    phone: "+47 76 08 49 00",
    email: "booking@lofotr.no",
    logoFilename: "lofotr-vikingmuseum.jpg",
  },
  {
    name: "Lofoten Krigsminnemuseum",
    street: "Fiskergata 11",
    postalCode: "8300",
    city: "Svolvær",
    phone: "+47 917 30 328",
    email: "lofoten.krigsminnemuseum@museumnord.no",
    logoFilename: null,
  },
  {
    name: "Øksnes Museum",
    street: "Øksnes Museum",
    postalCode: "8432",
    city: "Alsvåg",
    phone: "+47 404 48 202",
    email: "oksnes.museum@museumnord.no",
    logoFilename: null,
  },
  {
    name: "Bø Museum",
    street: "Vinjesjøveien 31",
    postalCode: "8470",
    city: "Bø i Vesterålen",
    phone: "+47 47 46 85 64",
    email: "bo.museum@museumnord.no",
    logoFilename: null,
  },
  {
    name: "Andøymuseet",
    street: "Richard Withs gt. 9",
    postalCode: "8480",
    city: "Andenes",
    phone: "+47 46 70 68 47",
    email: "andoymuseet@museumnord.no",
    logoFilename: null,
  },
  {
    name: "Melbo Hovedgård",
    street: "Maren Frederiksens allé 1",
    postalCode: "8445",
    city: "Melbu",
    phone: "+47 481 88 590",
    email: "melbohovedgaard@museumnord.no",
    logoFilename: null,
  },
  {
    name: "Sortland Museum",
    street: "Strandgata 1",
    postalCode: "8400",
    city: "Sortland",
    phone: "+47 76 15 40 00",
    email: "sortland.museum@museumnord.no",
    logoFilename: null,
  },
  {
    name: "Ballangen Museum",
    street: "Syllaveien 36",
    postalCode: "8540",
    city: "Ballangen",
    phone: "+47 76 96 96 50",
    email: "narvik@museumnord.no",
    logoFilename: null,
  },
  {
    name: "Korsnes museum",
    street: "",
    postalCode: "",
    city: "Korsnes",
    phone: "+47 76 96 96 50",
    email: "narvik@museumnord.no",
    logoFilename: null,
  },
  {
    name: "Kjøpsvik Museum",
    street: "Hovedveien 20",
    postalCode: "8590",
    city: "Kjøpsvik",
    phone: "+47 76 96 96 50",
    email: "narvik@museumnord.no",
    logoFilename: null,
  },
  {
    name: "Sørvågen Radio",
    street: "Moskenesveien 889",
    postalCode: "8392",
    city: "Sørvågen",
    phone: "+47 76 09 14 88",
    email: "nfmuseum@museumnord.no",
    logoFilename: null,
  },
  {
    name: "Fygle Museum",
    street: "Fygleveien 109",
    postalCode: "8370",
    city: "Leknes",
    phone: "+47 76 08 49 00",
    email: "booking@lofotr.no",
    logoFilename: null,
  },
  {
    name: "Skaftnes Gård",
    street: "Ureveien 106",
    postalCode: "8352",
    city: "Sennesvik",
    phone: "+47 76 08 49 00",
    email: "booking@lofotr.no",
    logoFilename: null,
  },
  {
    name: "Neptun Sildoljefabrikk",
    street: "Moloveien 1",
    postalCode: "8445",
    city: "Melbu",
    phone: "+47 76 15 40 00",
    email: "neptun@museumnord.no",
    logoFilename: null,
  },
  {
    name: "Jennestad Handelssted",
    street: "Jennestad",
    postalCode: "8400",
    city: "Sortland",
    phone: "+47 46 70 45 60",
    email: "sortland.museum@museumnord.no",
    logoFilename: null,
  },
];
