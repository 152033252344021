import { Trans } from "@lingui/macro";
import { Box, Flex } from "@mantine/core";
import { createFileRoute } from "@tanstack/react-router";

import CreateCustomer from "@/features/customers/create-customer/create-customer";
import CustomersTable from "@/features/customers/customers-table";

export const Route = createFileRoute("/_auth-layout/customers/")({
  component: () => <CustomersPage />,
});

function CustomersPage() {
  return (
    <Box p="lg">
      <Flex align="center" justify="space-between">
        <h1>
          <Trans>Kunderegister</Trans>
        </h1>

        <CreateCustomer />
      </Flex>

      <CustomersTable />
    </Box>
  );
}
